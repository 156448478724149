import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import AdminHeader from "../components/adminHeader";
import AdminSidebar from "../components/adminSidebar";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const CreateAdmin = () => {
  const [phoneshow, setPhoneshow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeA, setPasswordTypeA] = useState("password");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmpassword: "",
      phone: "",
    },

    onSubmit: async (values, { resetForm }) => {
      if (phoneNumber !== null) {
        const data = {
          name: values.name,
          email: values.email,
          password: values.password,
          phone: phoneNumber,
          role: "1",
        };

        await axios
          .post(
            `${process.env.REACT_APP_SERVER_BASE_URI}/sellerregistration`,
            data
          )
          .then((res) => {
            if (res.data.status === 201) {
              toast.success("Admin account created successfully");
              resetForm({ values: "" });
              setPhoneNumber("");
            }
          })
          .catch((err) => {
            if (err.response.data.status === 401) {
              toast.error("Admin Already Registered");
            }
          });
      } else {
        setPhoneshow(true);
      }
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z ]{3,40}$/, "Please Enter valid name!")
        .required("Name is required!"),
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@((theresellerapp+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          "Email must be a valid email address!"
        )
        .required("Email is required!"),
      password: Yup.string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character!"
        )
        .required("Please enter your password!"),
      confirmpassword: Yup.string()
        .required("Please enter your confirm  password!")
        .oneOf(
          [Yup.ref("password"), null],
          "Password & Confirm password isn't matching!"
        ),
    }),
  });

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString.target.value).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      setPhoneNumber(
        [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
      );
    } else {
      setPhoneNumber(null);
    }
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePasswordA = () => {
    if (passwordTypeA === "password") {
      setPasswordTypeA("text");
      return;
    }
    setPasswordTypeA("password");
  };

  return (
    <>
      <div className="dashboard-section">
        <div className="bg-pattern"></div>
        {/* <!----- Sidebar Section Start -----> */}
        <AdminSidebar />
        <div className="dashboard-body">
          <AdminHeader />
          <div className="page-content">
            <div className="main-wrapper w-100">
              <div className="sidebar-wrapper">
                {/* <!----- Sidebar Section Start -----> */}
              </div>
              <div className="main-content">
                {/* <!----- Dashboard Body Start -----> */}

                <div className="all-section-cover px-3 px-lg-3 px-xl-4 px-xxl-5 mt-5 admin-container">
                  {/* {isloading ? */}
                  <section className="driver-records" id="activeClients">
                    <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                      <h2 className="mb-3">Create Admin Account</h2>
                    </div>
                    <div>
                      <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="mb-3 col-md-12">
                              <label for="Name" className="form-label">
                                Name<span className="asterisk_admin"> </span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Please Enter Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                autoComplete="off"
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="error">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label for="email" className="form-label">
                                E-mail<span className="asterisk_admin"> </span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="email"
                                placeholder="john.doe@theresellerapp.com"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                autoComplete="off"
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="error">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label for="phone" className="form-label">
                                Phone Number
                                <span className="asterisk_admin"> </span>
                              </label>
                              <input
                                className="form-control"
                                type="tel"
                                name="phone"
                                placeholder="Please Enter Phone Number"
                                value={phoneNumber}
                                onChange={(event) => {
                                  formatPhoneNumber(event);
                                  setPhoneshow(false);
                                }}
                                autoComplete="off"
                              />
                              {phoneshow && (
                                <div className="error">
                                  Please enter a valid phone number!
                                </div>
                              )}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label for="password" className="form-label">
                                Create Password
                                <span className="asterisk_admin"> </span>
                              </label>
                              <input
                                className="form-control"
                                type={passwordType}
                                name="password"
                                placeholder="Please Enter Password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                autoComplete="off"
                              />

                              <Link to="" onClick={togglePassword}>
                                {passwordType === "password" ? (
                                  <span className="pass_icon eye_iconA">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="pass_icon eye_iconA">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                                    </svg>
                                  </span>
                                )}
                              </Link>
                              {formik.touched.password &&
                              formik.errors.password ? (
                                <div className="error">
                                  {formik.errors.password}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label
                                for="confirmpassword"
                                className="form-label"
                              >
                                Confirm Password
                                <span className="asterisk_admin"> </span>
                              </label>
                              <input
                                className="form-control"
                                type={passwordTypeA}
                                name="confirmpassword"
                                placeholder="Please Enter Confirm Password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmpassword}
                                autoComplete="off"
                              />
                              <Link to="" onClick={togglePasswordA}>
                                {passwordTypeA === "password" ? (
                                  <span className="eye_iconA pass_icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="eye_iconA pass_icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                                    </svg>
                                  </span>
                                )}
                              </Link>
                              {formik.touched.confirmpassword &&
                              formik.errors.confirmpassword ? (
                                <div className="error">
                                  {formik.errors.confirmpassword}
                                </div>
                              ) : null}
                            </div>

                            <div className="mb-3 col-md-12 text-right ">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  onReset={formik.resetForm}
                                >
                                  Create Admin
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
                <div></div>
              </div>
            </div>
            <ToastContainer autoClose={8000} position="top-center" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAdmin;
