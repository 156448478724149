import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Header = () => {
  return (
    <div>
      <header className="headerSec">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <Link className="navbar-brand" to="#">
              <img src={`${process.env.PUBLIC_URL}/Images/Logo.png`} className="img-fluid" alt="Site Logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul className="navbar-nav customNav_bar">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="https://myresellerapp.com" target='_blank'>About Us</a>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">Login</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to='/create-account'>Create Account</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
export default Header;