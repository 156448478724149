import { takeLatest } from "redux-saga/effects";
import {LoginUsersaga, ProfileUsersaga, Productbykeywordsaga, Soldproductbykeywordsaga} from "../saga/userSaga";

import * as types from "../types/index";


export default function* watchUserAuthentication() {
 
  yield takeLatest(types.GET_USER_LOGIN, LoginUsersaga);
  yield takeLatest(types.GET_COMPLETE_PROFILE, ProfileUsersaga);
  yield takeLatest(types.GET_PRODUCTS_REQUESTED, Productbykeywordsaga);
  yield takeLatest(types.GET_SOLD_PRODUCTS_REQUESTED, Soldproductbykeywordsaga);

}
