import "./App.css";
import "./responsive.css";
import React, { useEffect } from "react";
import SignUp from "./pages/signUp";
import { Routes, Route, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import SignIn from "./pages/signIn";
import Header from "./components/header";
import CompleteProfile from "./pages/createProfile";
import Home from "./pages/home";
import Searchlist from "./pages/searchPage";
import ProductDetails from "./pages/productDesc";
import FolderPage from "./pages/folderPage";
import Mycomp from "./pages/myComp";
import CheckoutForm from "./pages/checkoutForm";
import PrivateRoutes from "./helpers/privateRoutes";
import Subscription from "./pages/subscription";
import ChangePassword from "./pages/changePassword";
import ForgotPassword from "./pages/forgotPassword";
import OtpVerify from "./pages/otpVerify";
import ResetPassword from "./pages/resetPassword";
import BarcodeScan from "./components/barcode";
import Profile from "./pages/profile";
import Dashboard from "./pages/dashboard";
import CreateAdmin from "./pages/createAdmin";
import VerocsvFile from "./pages/csvFile";
import DeleteUser from "./components/deletedUser";
import AdminSignin from "./pages/adminSignin";
import { roleId } from "./redux/actions/users";
import { useSelector } from "react-redux";
import Trial from "./pages/trial";

function App() {
  const role = useSelector((state) => state.getroleId);
  const roleId = role.response;
  const navigate = useNavigate();
  let authorised = localStorage.getItem("token");

  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/admin" element={<AdminSignin />}></Route>
        <Route path="/create-account" element={<SignUp />} />
        {/* <Route path='/create-profile' element={<CompleteProfile />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/otp-verification" element={<OtpVerify />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>

        <Route element={<PrivateRoutes />}>
          {roleId == 2 && <Route path="/home" element={<Home />}></Route>}
          {roleId == 2 && (
            <Route
              path="/searchlist/:Product_name"
              element={<Searchlist />}
            ></Route>
          )}
          {roleId == 2 && (
            <Route
              path="/search-list-by-barcode/:bar_code"
              element={<Searchlist />}
            ></Route>
          )}
          {roleId == 2 && (
            <Route
              path="/product-details/:item_id"
              element={<ProductDetails />}
            ></Route>
          )}
          {roleId == 2 && (
            <Route path="/folders-page" element={<FolderPage />}></Route>
          )}
          {roleId == 2 && (
            <Route path="/comp-history" element={<Mycomp />}></Route>
          )}
          {authorised && (
            <Route path="/change-password" element={<ChangePassword />}></Route>
          )}
          {roleId == 2 && (
            <Route path="/checkout" element={<CheckoutForm />}></Route>
          )}
          {roleId == 2 && (
            <Route path="/barcode-scan" element={<BarcodeScan />}></Route>
          )}
          {roleId == 2 && (
            <Route path="/profile-setting" element={<Profile />}></Route>
          )}
          {roleId == 2 && (
            <Route path="/subscription" element={<Subscription />} />
          )}
          {roleId == 2 && <Route path="/trial" element={<Trial />} />}
          {roleId == 1 && (
            <Route path="/admin/dashboard" element={<Dashboard />}></Route>
          )}
          {roleId == 1 && (
            <Route path="/admin/create-admin" element={<CreateAdmin />}></Route>
          )}
          {roleId == 1 && (
            <Route path="/admin/vero-csv" element={<VerocsvFile />}></Route>
          )}
          {roleId == 1 && (
            <Route path="/admin/delete-users" element={<DeleteUser />}></Route>
          )}
        </Route>
      </Routes>
    </>
  );
}

export default App;
