import React from "react";

const PrivacyPolicy = () => {
  
    return (
        <>
            <strong>Privacy Policy
                Last updated: March 19, 2023</strong><p>
                ReSeller, found at theresellerapp.com and myresellerapp.com is owned and operated by
                Reseller Inc. (collectively, “ReSeller,”, “Reseller”, “we,” or “us”). This Privacy Policy applies to
                activities by Reseller Inc. and its subsidiaries.
                We recognize that your privacy is very important and we take it seriously. This Privacy Policy
                (“Privacy Policy”) describes our policies and procedures on the collection, use, disclosure, and
                sharing of your personal information or personal data when you use the ReSeller service.
                Capitalized terms that are not defined in this Privacy Policy have the meaning given to them in
                our User Agreement.
                We may make changes to this policy as needed to protect our customers even more. Please
                take note of the “Last updated” at the top of this page.</p>
            <strong>The Information We Collect:</strong>
            <p>
                We collect information directly from individuals, from third parties, and automatically through the
                ReSeller Platform.
                Account and Profile Information: When you create an account on theresellerapp.com, we collect
                your email address and the password you create. Password data is encoded and will only be
                known by you.
                Payment Information. If you choose to use a paid ReSeller account or service, our payment
                processing vendor collects your credit card information and billing address.
                If you optionally add to your account your name, postal code or telephone number for sms
                alerts, that data will also be safely saved on our system. You may not, however, visit our site
                anonymously.</p><strong>
                Your Saved Content:</strong><p>
                We collect and store the information and content that you submit to theresellerapp.com, which
                includes your eBay searches data and Sell One Like This data. This is stored to provide the
                functionality offered on the ReSeller service and cannot provide the promised services without
                storing this information for your continued use.</p><strong>
                Integrated Service Providers:</strong><p>
                eBay: If you elect to connect your eBay account to theresellerapp.com to create draft listings on
                eBay, we will receive a Unique Connection Token from eBay that allows us access to certain
                data from that eBay as required to create a draft or listing. We store this information to allow us
                to provide eBay functionality as promised in the Terms of Use for ReSeller.
                If you remove the eBay connection from our service from the ReSeller account management
                page or revoke access via a setting in your eBay account, we will no longer have access to this
                data.
                Automatically Collected Information About Your Activity. We use cookies, log files, pixel tags,
                local storage objects, to automatically collect information about your activities, such as your
                searches, page views, date and time of your visit, and other information about your use of
                theresellerapp.com <u>ONLY as is pertains to the use while on the theresellerapp.com.</u>
                We also collect and may store information that your computer or mobile device provides to us in
                connection with your use of our service such as your browser type, type of computer or mobile
                device, browser language, IP address, mobile carrier, unique device identifier, location, and
                requested and referring URLs. <u>We DO NOT track or use any activity not associated with
                    theresellerapp.com site.</u></p>
            <strong>Communications:</strong><p>
                When you communicate with us (via email, phone, sms, through theresellerapp.com, or
                otherwise), we may maintain a record of your communication. We also save data about email
                and SMS alert messages sent.</p>
            <strong>How We Use Your Information:</strong><p>
                We do not sell your personal data to anyone. We use the information we collect for the following
                purposes:</p>
            <p>
                <b>Provide our Services.</b> To provide you the services we offer on theresellerapp.com, communicate
                with you about your use of theresellerapp.com, respond to your inquiries, provide
                troubleshooting, and for other customer service purposes.
                Personalization. To tailor the content and information that we may send or display to you on
                theresellerapp.com, to offer location customization, and personalized help and instructions, and
                to otherwise personalize your experiences while using theresellerapp.com.
                Marketing and Promotions. For marketing and promotional purposes, such as to send you news
                and newsletters, special offers, and promotions, or to otherwise contact you about products or
                information we think may interest you, including information about third party products and
                services.</p><p>
                <b>Analytics.</b> To gather metrics to better understand how users access and use
                theresellerapp.com; to evaluate and improve theresellerapp.com, and to develop new products
                and services.</p><p>
                <b>Comply with Law.</b> To comply with legal obligations, as part of our general business operations,
                and for other business administration purposes.
                Prevent Misuse. Where we believe necessary to investigate, prevent or take action regarding
                illegal activities, suspected fraud, situations involving potential threats to the safety of any
                person or violations of our User Agreement or this Privacy Policy.</p>
            <b>How We Share Your Information:</b>
            <p>We share information as set forth below, and where individuals have otherwise consented:
                Service Providers. We may share your contact information with third party service providers who
                use this information to perform services for us, such as eBay, payment processors, hosting
                providers, auditors, advisors, consultants, customer service and support providers, email and
                SMS delivery services. The service providers we use are GDPR compliant and are committed to
                the same level of data privacy as we are.</p>
            <p><b>Business Transfers.</b> We may disclose or transfer information, including personal information, as
                part of any merger, sale, and transfer of our assets, acquisition or restructuring of all or part of
                our business, bankruptcy, or similar event.</p>
            <p><b>Legally Required.</b> We may disclose your information if we are required to do so by law.
                Protection of Rights. We may disclose information where we believe it necessary to respond to
                claims asserted against us or comply with legal process (e.g., subpoenas or warrants), enforce
                or administer our agreements and terms, for fraud prevention, risk assessment, investigation,
                and protect the rights, property or safety of Integrinet Inc., its users, or others.
                Your Content and Activity. Your Proprietary Content including your Saved eBay Searches,
                Drafts, and search results will NOT be shared unless required by subpoenas or warrants other
                than as follows. The necessary data will be sent to eBay anonymously via the eBay API in order
                to perform eBay searches and return search results.</p>
            <p><b>Cookies, Pixels and Tracking.</b>
                We and our third party providers use cookies, clear GIFs/pixel tags, JavaScript, local storage,
                log files, and other mechanisms to automatically collect and record information about your
                usage and browsing activities on theresellerapp.com and across third party sites or online
                services. We may combine this information with other information we collect about users. Below,
                we provide a summary of these activities.</p>
            <p><b>Cookies.</b> These are small files with a unique identifier that are transferred to your browser
                through our websites. They allow us to remember users who are logged in, to understand how
                users navigate through and use theresellerapp.com, and to display personalized content. You
                may turn off cookies in your browser but this site will not function properly. If you'd like to delete
                cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of
                your web browser.</p>
            <p><b>Pixels, clear GIFs. </b>These are tiny graphics with a unique identifier, similar in function to cookies,
                which we use in our emails to let us know when they have been opened, so we can gauge the
                effectiveness of our communications.</p>
            <p><b>Analytics Tools.</b> We may use internal and third party analytics tools, including Google Analytics.
                The third party analytics companies we work with may combine the information collected with
                other information they have independently collected from other websites and/or other online
                products and services. Their collection and use of information is subject to their own privacy
                policies.</p>
            <b>Ads and Personalization:</b>
            <p>We may display personalized ads (including sponsored content) in email and SMS alerts and on
                theresellerapp.com.</p>
            <b>How We Protect Your Information.</b>
            <p>The security of your information is important to us. We have implemented safeguards to protect
                the information we collect. However, no website or internet transmission is completely secure.
                We urge you to take steps to keep your personal information safe, such as choosing a strong
                password and keeping it private, as well as logging out of your user account, and closing your
                web browser when finished using theresellerapp.com on a shared or unsecured device.</p>
            <p><b>Access and Change Your Information.</b>
                You may update or correct your account information at any time by logging in to your account.
                You may also make a number of other adjustments to settings as described in more detail in the
                following section about Your Choices.</p>
            <b>Your Choices:</b>
            <p>You may, of course, decline to submit information through theresellerapp.com, in which case we
                may not be able to provide certain services to you. You may also control the types of
                notifications and communications we send. Here is some further information about some of your
                choices:</p>
            <p><b>Your Content.</b> You may edit or delete any user content such as Saved eBay Searches or search
                results. Items found in your search History will auto delete in 90 days.</p>
            <p><b>Adult Content.</b> The only possible adult content on theresellerapp.com would be from eBay
                listings in search results or alerts that contain adult content and we do not have any control of
                that.</p>
            <p><b>Emails and Communications.</b> When you sign up for an theresellerapp.com account, as part of
                the service, you will receive a periodic system and promotional email. You will also receive alert
                emails and SMS messages if you explicitly subscribe to those services. You can manage
                promotional emails via a link in the promotional email. You can manage alert emails and alert
                SMS messages via a link in the alert or on theresellerapp.com. If you opt out of receiving
                promotional or alert messages, we may still send you transactional emails about your account
                or any services you have requested or received from us.</p>
            <p><b>Deleted or Deactivated Account.</b> You may choose to temporarily Deactivate Your Account by
                activating closing your account in your account profile.
                Deactivated account data will be deleted in 30 days and can be reactivated by you at any time.
                You may choose to Delete Your Account by contacting us and requesting to delete your
                account. We will respond to your request within 30 days.
                We will retain your information for as long as your account is active or as needed to provide you
                services. We will retain and use your information as necessary to comply with our legal
                obligations, resolve disputes, and enforce our agreements.</p>
            <b>Transferring Your Data:</b>
            <p>Reseller Inc. is headquartered in the United States, and has operations, entities and service
                providers in the United States. As such, we and our service providers may transfer your
                personal information to, or access it in, jurisdictions (including the United States) that may not
                provide equivalent levels of data protection as your home jurisdiction. We will take steps to
                ensure that your personal information receives an adequate level of protection in the
                jurisdictions in which we process it.</p>
            <b>Children’s Privacy</b><p>
                We do not knowingly collect or solicit personal information from anyone under the age of 13 or
                knowingly allow such persons to register. If we become aware that we have collected personal
                information from a child under the relevant age without parental consent, we take steps to
                delete that information. We are in compliance with the requirements of COPPA (Childrens
                Online Privacy Protection Act). Our website, products and services are all directed to people
                who are at least 18 years old or older.</p>
            <b>Links to Other Websites</b>
            <p>theresellerapp.com may contain links to third party sites or online services. We are not
                responsible for the practices of such third parties, whose information practices are subject to
                their own policies and procedures, not to this Privacy Policy.</p>
            <b>Your California Privacy Rights:</b>
            <p>California law permits users who are California residents to request and obtain from us once a
                year, free of charge, a list of the third parties to whom we have disclosed their personal
                information (if any) for their direct marketing purposes in the prior calendar year, as well as the
                types of personal information disclosed to those parties. We do not disclose any personal
                information to third parties for their direct marketing purposes.</p>
            <b>Contact Us</b>
            <p>If you have any questions about our practices or this Privacy Policy, please contact us.</p>
            <b>Changes to Our Privacy Policy</b><p>
                If we change our privacy policies and procedures, we will post those changes on this page. If we
                make any changes to this Privacy Policy that materially change how we treat your personal
                information, we will endeavor to provide you with reasonable notice of such changes, such as
                via prominent notice on theresellerapp.com or to your email address of record, and where
                required by law, we will obtain your consent or give you the opportunity to opt out of such
                changes.</p>
            <b>Terms and Conditions</b>
            <p>Please also visit our User Agreement section establishing the use, disclaimers, and limitations
                of liability governing the use of our website at www.theresellerapp.com</p>
            <b>Your Consent:</b>
            <p>By using this website, you consent to this Privacy Policy and User Agreement.</p>
        </>
    )
}
export default PrivacyPolicy;