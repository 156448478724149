import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { userSignin, roleId } from "../redux/actions/users";
import Header from "../components/header";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminSignin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const logindata = useSelector((state) => state.signinReducer);
  const roledetails = useSelector((state) => state.getroleId);
  let authorised = localStorage.getItem("token");
  const role = roledetails.response;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: async (values) => {
      const data = {
        email: values.email,
        password: values.password,
        userType: 1,
      };
      dispatch(userSignin(data));
    },

    //yup validaiton for login module.
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address!")
        .required("Please enter your email address!"),
      password: Yup.string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Please enter correct password!"
        )
        .required("Please enter your password!"),
    }),
  });

  useEffect(() => {
    authorised && role == 1 ? navigate("/admin/dashboard") : navigate("/admin");
  }, []);

  useEffect(() => {
    if (logindata.hasOwnProperty("response")) {
      if (logindata.response.hasOwnProperty("response")) {
        if (logindata.response.response.status === 200) {
          dispatch(roleId(logindata.response.response.data.data.role));
          //for admin role login
          if (logindata.response.response.data.data.role === 1) {
            setBtnDisabled(true);
            localStorage.setItem(
              "name",
              logindata.response.response.data.data.name
            );
            localStorage.setItem(
              "token",
              logindata.response.response.data.data.token
            );
            toast.success(logindata.response.response.data.message);
            setTimeout(() => {
              navigate("/admin/dashboard");
              window.location.reload();
            }, 1000);
          } else {
            toast.error("Email or password does not exist");
            localStorage.clear();
            navigate("/admin");
          }
        } else {
          toast.error(logindata.response.response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  }, [logindata.response]);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="bg-img">
      <div>
        <header className="headerSec">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container">
              <Link className="navbar-brand" to="#">
                <img
                  src={`${process.env.PUBLIC_URL}/Images/Logo.png`}
                  className="img-fluid"
                  alt="Site Logo"
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNav"
              >
                <ul className="navbar-nav customNav_bar">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      aria-current="page"
                      href="https://myresellerapp.com"
                      target="_blank"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/admin">
                      Login
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <section className="signUp_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2 col-md-2">
              <div className="signUp_Box"></div>
            </div>

            <div className="col-xl-6 col-lg-8 col-md-8">
              <div className="signUp_Box px-lg-5">
                <p>Admin Portal Login</p>
                <form className="signUpForm_box" onSubmit={formik.handleSubmit}>
                  <div className="mb-4 customInput_Box">
                    <span className="asterisk_input"> </span>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error mt-3">{formik.errors.email}</div>
                    ) : null}
                    <span className="eye_icon">
                      <svg
                        id="Group_23476"
                        data-name="Group 23476"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                      >
                        <rect
                          id="Rectangle_17855"
                          data-name="Rectangle 17855"
                          width="27"
                          height="27"
                          opacity="0"
                        />
                        <g
                          id="Group_23475"
                          data-name="Group 23475"
                          transform="translate(1.949 4.08)"
                        >
                          <g
                            id="Group_23474"
                            data-name="Group 23474"
                            transform="translate(0 0)"
                          >
                            <g id="Group_23472" data-name="Group 23472">
                              <rect
                                id="Rectangle_17856"
                                data-name="Rectangle 17856"
                                width="22.16"
                                height="17.899"
                                rx="3.14"
                                fill="none"
                                stroke="#6fb679"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                            </g>
                            <g
                              id="Group_23473"
                              data-name="Group 23473"
                              transform="translate(0 2.131)"
                            >
                              <path
                                id="Path_16267"
                                data-name="Path 16267"
                                d="M1.57,9.42l9.989,8.523a1.7,1.7,0,0,0,2.182,0L23.73,9.42"
                                transform="translate(-1.57 -9.42)"
                                fill="none"
                                stroke="#6fb679"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <div className="mb-4 customInput_Box">
                    <span className="asterisk_input"> </span>
                    <input
                      type={passwordType}
                      autoComplete="off"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />

                    <Link to="" onClick={togglePassword}>
                      {passwordType === "password" ? (
                        <span className="eye_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" />
                          </svg>
                        </span>
                      ) : (
                        <span className="eye_icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                          >
                            <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                          </svg>
                        </span>
                      )}
                    </Link>

                    {formik.touched.password && formik.errors.password ? (
                      <div className="error mt-3">{formik.errors.password}</div>
                    ) : null}
                  </div>
                  <div className="signUpForm_BTN">
                    <button
                      className="btn"
                      type="submit"
                      disabled={btnDisabled}
                    >
                      Login
                    </button>
                    <ToastContainer autoClose={8000} position="top-center" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-2"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AdminSignin;
