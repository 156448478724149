import React, { useState, useEffect, useMemo } from "react";
import {
  getproductbykeyword,
  getsoldproductbykeyword,
  pageId,
} from "../redux/actions/users";
import {
  activeListingAction,
  componentReRenderAction,
} from "../redux/actions/activeListing";
import { useSelector, useDispatch } from "react-redux";
import Mainheader from "../components/mainheader";
import { Link, useParams } from "react-router-dom";
import Activelist from "../components/activeList";
import axios from "axios";
import Loader from "../components/loader";
import { checkSubscription } from "../helpers/common";

const Searchlist = () => {
  const [show, setShow] = useState(false);
  let dispatch = useDispatch();
  const [isloading, setIsloading] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const Soldproduct_details = useSelector(
    (state) => state.getsoldproductbykeywordReducer
  );
  const Barcode_product = useSelector(
    (state) => state.getproductbybarcodeReducer
  );
  const getActiveListingStatus = useSelector((state) => state.listingReducer);
  const getReRenderCompStatus = useSelector(
    (state) => state.componetReRenderReducer
  );
  const searchdatakeyword = useSelector((state) => state.getsearchKeyword);
  let { Product_name, bar_code } = useParams();
  localStorage.setItem(
    "ProductName",
    Product_name === undefined ? bar_code : Product_name
  );

  useEffect(() => {
    checkSubscription();
  }, []);

  return (
    <>
      <Mainheader />
      {Product_name !== "null" ? (
        <div className="container">
          <div className="page_wrapper">
            <main className="main_content_wrapper">
              <Activelist />
            </main>
          </div>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="page_wrapper">
              <main className="main_content_wrapper">
                <div className="row">
                  <div className="col-12 text-center">
                    <div>
                      <h4 className="home_heading">
                        No search results is available to show
                      </h4>
                    </div>
                    <div className="mobile-nav text-start">
                      <Link to="/home">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.2"
                          height="24.1"
                          viewBox="0 0 14.2 24.1"
                        >
                          <path
                            id="chevron_left_FILL0_wght400_GRAD0_opsz48"
                            d="M28.05,36,16,23.95,28.05,11.9l2.15,2.15-9.9,9.9,9.9,9.9Z"
                            transform="translate(-16 -11.9)"
                          />
                        </svg>
                        No search results is available to show
                      </Link>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Searchlist;
