import React, { useState } from "react";
import Header from "../components/header";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/loader";

const OtpVerify = () => {
  const navigate = useNavigate();
  const useremail = localStorage.getItem("email");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    onSubmit: async (values) => {
      const data = {
        otp: values.otp,
        email: useremail,
      };
      //verify user otp of forgot password
      const res = await axios
        .post(`${process.env.REACT_APP_SERVER_BASE_URI}/verfiyotp`, data)
        .then((response) => {
          if (response.data.status === 200) {
            setBtnDisabled(true);
            setTimeout(() => {
              toast.success("OTP verified successfully");
            }, 1000);
            navigate("/reset-password");
          }
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            formik.resetForm({ values: "" });
            toast.error("Please enter the correct OTP");
          }
        });
    },

    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^\d{4}$/, "OTP must be 4 digits numerical only")
        .required("OTP is required!"),
    }),
  });

  //forgot password
  const handleOtp = () => {
    setIsloading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URI}/forGotPassword`, {
        email: useremail,
      })
      .then((response) => {
        if (response.data.status === 200) {
          formik.resetForm({ values: "" });
          setIsloading(false);
          setTimeout(() => {
            toast.success("OTP sent successfully");
          }, 1000);
          navigate("/otp-verification");
        }

        if (response.data.status === 400) {
          setIsloading(false);
          toast.error("Email does not exist");
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="bg-img">
      <Header />
      {!isloading ? (
        <section className="signUp_sec mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-2 col-md-2">
                <div className="signUp_Box"></div>
              </div>
              <div className="col-xl-6 col-lg-8 col-md-8">
                <div className="signUp_Box fspan px-lg-5">
                  <h1>Know before you buy</h1>
                  <p>OTP Verification</p>
                  <form
                    className="signUpForm_box"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="customInput_Box">
                      <span className="asterisk_input"> </span>
                      <input
                        type="number"
                        className="form-control"
                        id="otp"
                        name="otp"
                        placeholder="One Time Password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.otp}
                      />
                      {formik.touched.otp && formik.errors.otp ? (
                        <div className="error">{formik.errors.otp}</div>
                      ) : null}
                    </div>

                    <div className="signUpForm_BTN reBtn mt-3">
                      <button
                        className="btn"
                        type="submit"
                        disabled={btnDisabled}
                      >
                        SUBMIT OTP
                      </button>

                      <span>
                        Didn't receive email?
                        <Link
                          onClick={() => {
                            handleOtp();
                          }}
                        >
                          {" "}
                          Click to resend
                        </Link>
                      </span>
                      <span>
                        <Link to="/">&#8592; Back to log in</Link>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Loader />
      )}
      <ToastContainer autoClose={8000} position="top-center" />
    </div>
  );
};
export default OtpVerify;
