import React,{useState,useEffect} from 'react'
import AdminHeader from './adminHeader'
import AdminSidebar from './adminSidebar'
import { ToastContainer,toast } from 'react-toastify'
import axios from 'axios'
import Loader from './loader'
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const DeleteUser = () => {   
    const [users,setUsers] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        deletedUser();
}, [])

//get deleted user by admin
let deletedUser = () =>{ axios.get(`${process.env.REACT_APP_SERVER_BASE_URI}/deleteduserbyadmin`, {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
})
 .then((response) => {
        
        if (response.data.status === 200) {
            setIsloading(true)
            setUsers(response.data.data)
        } 
    }).catch((err) => {
        throw err   
    })  
 }

//----get restore user -----
let restoreUser = (deletUserId) =>{
        confirmAlert({
            title: '',
            message: 'Do you really want to restore this customer ?',
            buttons: [
            {
                
                label: "Yes, I want to restore them",
                onClick: () =>  {axios.delete(`${process.env.REACT_APP_SERVER_BASE_URI}/restoreUser?deletUserId=${deletUserId}`,{
                    headers : {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                .then((res) => {
                    toast.success('Customer account restore successfully');
                    deletedUser();
                })
                .catch((err) =>{
                    throw err
                })}
            },
            {
                label: "Cancel",
                onClick: () => navigate('/admin/delete-users')
            }
            ]
        });        
}
  
return (
    <>
    <div className="dashboard-section">
     <div className="bg-pattern">
       </div>
         {/* <!----- Sidebar Section Start -----> */}
            <AdminSidebar />
                {/* <!----- Dashboard Body Start -----> */}
                {isloading ?
                <div className="dashboard-body">
                    <AdminHeader />
                    <div className="page-content">
                        <div className="page-head-sec csv">
                            <h3>Restore Customers</h3> 
                        </div>
                        <div className="data-container mt-3">
                                <div className="data-head-sec">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <h4 className="data-heading">Customer Details</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper">
                                    <div className="table-responsive">
                                        <table id="example" className="table table-striped customer-table" style={{ "width": "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>Acc. Deletion Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               
                                                {users.length > 0 ? users.map((userdata) =>{
                                                   return( 
                                                <tr>
                                                    <td>{userdata.users  && userdata.users !== null ? userdata.users.name : "N/A"}</td>
                                                    <td>{userdata.users  && userdata.users !== null ? userdata.users.email : "N/A"}</td>
                                                    <td>{userdata.users  && userdata.users !== null ? userdata.users.phone : "N/A"}</td>
                                                    <td>{userdata.users  && userdata.users !== null ? moment(userdata.createdAt).format('ll') : "N/A"}</td>
                                                    <td>
                                                        <div className="action-btn">
                                                            <button className="delete-btn" title="Restore user" onClick={() => {restoreUser(userdata._id)}}>
                                                                <i className="bx bxs-user-plus"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                             )}) : <h4 style={{ 'textAlign': 'center' }}>No data found</h4>} 
                                                  
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <ToastContainer/>
                </div>
            : <Loader />} 
            </div>
    </>
  )
}

export default DeleteUser