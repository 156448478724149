import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  getproductbykeyword,
  sortApiDataByPrice,
  pageId,
  filterMessage,
  conditionMessage,
  getconditionRequest,
} from "../redux/actions/users";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./loader";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const Condition = () => {
  let dispatch = useDispatch();
  const conditionmessage = useSelector((state) => state.getconditionMessage);
  const [isloading, setIsloading] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const Paidshipping_data = useSelector((state) => state.getpaidData);
  const priceRange = useSelector((state) => state.rangeReducer);
  let { Product_name, bar_code } = useParams();
  const sortStatus = useSelector((state) => state.sortReducer);
  const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;
  const keywordApiRoute = "getItemByKeyword?items_name";
  const barcodeApiRoute = "getActiveListingByBarcode?barcodeNumber";

  function roundUpToNearestMultiple(number, multiple) {
    return Math.ceil(number / multiple) * multiple;
  }

  let shippingCondition = (shippedCondition) => {
    dispatch(pageId(1));
    dispatch(getconditionRequest(shippedCondition));
  };

  return (
    <>
      {!isloading ? (
        <div>
          <div className="option-wrapper">
            <Dropdown drop="end" className="dropdown">
              <DropdownToggle
                className="btn p-0 sort-btn dropdown-toggle"
                variant=""
              >
                <span>Condition</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.717"
                  height="32"
                  viewBox="0 0 32.717 32"
                >
                  <path
                    id="filter_alt_FILL0_wght400_GRAD0_opsz48"
                    d="M22,40a1.964,1.964,0,0,1-2-2V26L8.05,10.75a1.513,1.513,0,0,1-.2-1.8A1.643,1.643,0,0,1,9.4,8H38.6a1.643,1.643,0,0,1,1.55.95,1.513,1.513,0,0,1-.2,1.8L28,26V38a1.964,1.964,0,0,1-2,2Zm2-13.8L36,11H12ZM24,26.2Z"
                    transform="translate(-7.642 -8)"
                  />
                </svg>
                {conditionmessage.response === "new" ? (
                  <span>&nbsp;New</span>
                ) : conditionmessage.response === "used" ? (
                  <span>&nbsp;Used</span>
                ) : conditionmessage.response === "none" ? (
                  ""
                ) : (
                  ""
                )}
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem("conditionFilter", "True");
                    shippingCondition("NEW");
                    dispatch(conditionMessage("new"));
                  }}
                >
                  New
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem("conditionFilter", "True");
                    shippingCondition("USED");
                    dispatch(conditionMessage("used"));
                  }}
                >
                  Used
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem("conditionFilter", "False");
                    shippingCondition("NONE");
                    dispatch(conditionMessage("none"));
                  }}
                >
                  None
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Condition;
