import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Home from "./home";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cancelSubscribed, subscriptionType } from "../redux/actions/users";

const Subscription = () => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const subscriptionId = localStorage.getItem("suscriptionId");
  const subscriptionEnd = localStorage.getItem("suscriptionEndDate");

  //cancel subscription API.
  let cancelSubscription = () => {
    if (
      window.confirm(
        "We are sorry to see you go! We keep your data for 30 days should you decide to return."
      )
    ) {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URI}/cancelSub`,
          {
            subscriptionId: subscriptionId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            setBtnDisabled(true);
            localStorage.removeItem("suscriptionId");
            dispatch(cancelSubscribed(response.data.data.is_deleted));
            dispatch(subscriptionType(response.data.data.suscriptionType));
            toast.success("Your subscription has been cancelled successfully");
            setTimeout(() => {
              hideModal();
            }, 1000);
          }
        })
        .catch((err) => {});
    } else {
      navigate("/home");
    }
  };
  const hideModal = () => {
    setIsOpen(false);
    navigate("/home");
  };
  return (
    <>
      <Home />
      <Modal show={isOpen}>
        <Modal.Header>
          <h4>Subscription Details</h4>
          <button
            type="button"
            className="close custom-close"
            onClick={hideModal}
            data-dismiss="modal"
            disabled={btnDisabled}
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            Are you sure to cancel the ReSeller subscription which will end on{" "}
            {moment(subscriptionEnd).format("ll")}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={() => {
                cancelSubscription();
              }}
              className="btn btn-success"
              disabled={btnDisabled}
            >
              Yes, Cancel my subscription
            </button>
            <button
              type="button"
              onClick={hideModal}
              className="btn btn-danger"
              disabled={btnDisabled}
            >
              No, I don't want to cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer autoClose={8000} position="top-center" />
    </>
  );
};
export default Subscription;
