import React,{ useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import axios from "axios";
import {getproductbykeyword,searchBarcode} from "../redux/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Mainheader from "./mainheader";
import Loader from "./loader";
import { confirmAlert } from 'react-confirm-alert';
import { checkSubscription } from "../helpers/common";

const BarcodeScan = () => {
    checkSubscription();
    const [barcodeSearch, setBarcodeSearch] = React.useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Product_details = useSelector(state => state.getproductbykeywordReducer)
    const searchdatabarcode = useSelector(state => state.getsearchBarcode)


//     navigator.getMedia = ( navigator.getUserMedia || // use the proper vendor prefix
//     navigator.webkitGetUserMedia ||
//     navigator.mozGetUserMedia ||
//     navigator.msGetUserMedia);

// navigator.getMedia({video: true}, function() {
// // webcam is available
// }, function() {
//     confirmAlert({
//                 title: 'Camera Connection',
//                 message: 'Please connect camera to scan barcode.',
//                 buttons: [
//                     {
//                         label: "Ok",
//                         onClick: () => navigate('/home')
//                     },
//                 ]
//             });
// });

    let saveCompHistory = (data) => {
        axios.post(`${process.env.REACT_APP_SERVER_BASE_URI}/comphistory`, data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },

            }).then((res) => { 
                navigate(`/search-list-by-barcode/${searchdatabarcode.response}`)    
            }).catch((err) => {
                 throw err
            })
    }
    
    useEffect(() => { 
                 
        if(barcodeSearch)
         {   
           if (barcodeSearch) {
            saveCompHistory({
                "keyword": barcodeSearch
            });

        } 
        else {
            if (barcodeSearch) {
                    saveCompHistory({
                        "keyword": barcodeSearch,
                    });
            }
        }
    }
    }, [Product_details,barcodeSearch])

    return (
        <>
           <Mainheader/>
            <section className="signUp_sec mt-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3">
    
                        </div>
                    </div>
                </div>
            </section>
            <div className="webCam">   
        {!barcodeSearch ?
               
                <BarcodeScannerComponent
                    onUpdate={(err, result) => { 
                        setBarcodeSearch(result.text)
                        dispatch(searchBarcode(result.text))
                        
                     }}
                     
                />
                : <Loader/>}
                </div>
        </>
    );
}

export default BarcodeScan;