import React from "react";

const TermsCondition = () => {
  return (
    <>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">$15 per month billed every month</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">
              <strong>Monthly:</strong> $15 per month, billed evry month until
              canceled
            </td>
          </tr>
          {/* <tr>
            <td scope="row">
              <strong>Trial:</strong> Trial days are given to you free with
              first charge at it's conclusion{" "}
            </td>
          </tr> */}
          <tr>
            <th scope="row">OR</th>
          </tr>
          <tr>
            <td scope="row">
              <strong>Yearly:</strong> $120.00 as a one time payment each year
              on renewal until canceled.
            </td>
          </tr>
          <tr>
            <td scope="row">
              <strong>Recurring Billing:</strong>Your card will be charged on
              the billing date until you cancel your service.
            </td>
          </tr>
        </tbody>
      </table>
      <h3>ReSeller Terms of Use</h3>
      <p>
        ReSeller provides a personalized subscription service that allows our
        members to access eBay information and product listings ("ReSeller
        content") over the Internet on Internet-connected computers, tablets,
        cell phones and other devices ("ReSeller ready devices").
      </p>
      <p>
        The ReSeller service is provided to you by Reseller Inc. You have
        accepted these Terms of Use, which govern your use of our service. As
        used in these Terms of Use, "ReSeller service", "our service" or "the
        service" means the personalized service provided by ReSeller for
        discovering and accessing ReSeller content, including all features and
        functionalities, recommendations and reviews, our websites, and user
        interfaces, as well as all content and software associated with our
        service. References to ‘you’ in these Terms of Use indicate the member
        who created the ReSeller account and whose payment method is charged.
      </p>
      <p>
        YOU AGREE TO THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER DESCRIBED
        IN SECTION 7 TO RESOLVE ANY DISPUTES WITH RESELLER (EXCEPT FOR MATTERS
        THAT MAY BE TAKEN TO SMALL CLAIMS COURT).
      </p>
      <h3>Subscription</h3>
      <p>
        <b>1.1.</b> Your ReSeller subscription will continue and automatically
        renew until terminated. To use the ReSeller service you must have
        Internet access and a ReSeller ready device and provide us with one or
        more Payment Methods. "Payment Method" means a current, valid, accepted
        method of payment, as may be updated from time to time and which may
        include payment through your account with a third party. You must cancel
        your subscription before it renews in order to avoid billing of the
        subscription fees for the next billing cycle to your Payment Method (see
        "Cancellation" below).
      </p>
      <p>
        <b>1.2.</b> We may offer a number of subscription plans, including
        subscriptions offered by third parties in conjunction with the provision
        of their own products and services. We are not responsible for the
        products and services provided by such third parties. Some subscription
        plans may have differing conditions and limitations, which will be
        disclosed at your sign-up or in other communications made available to
        you. You can find specific details regarding your ReSeller subscription
        by visiting the theresellerlife.com website and clicking on the
        "Account" link available at the top of the page. Promotional Offers. We
        may from time to time offer special promotional offers, plans or
        subscriptions (“Offers”). Offer eligibility is determined by ReSeller at
        its sole discretion and we reserve the right to revoke an Offer and put
        your account on hold in the event that we determine you are not
        eligible. Members of households with an existing or recent ReSeller
        subscription may not be eligible for certain introductory Offers. We may
        use information such as device ID, method of payment or an account email
        address used with an existing or recent ReSeller subscription to
        determine Offer eligibility. The eligibility requirements and other
        limitations and conditions will be disclosed when you sign-up for the
        Offer or in other communications made available to you.
      </p>
      <h3>Billing and Cancellation</h3>
      <p>
        <strong>2.1. Billing Cycle.</strong> The ReSeller service bills as a
        monthly service charging $15 each month until canceled. The subscription
        fee for the ReSeller service and any other charges you may incur in
        connection with your use of the service, such as taxes and possible
        transaction fees, will be charged to your Payment Method on the specific
        payment date indicated on the "Account" page. The length of your billing
        cycle will depend on the type of subscription that you choose when you
        signed up for the service. Subscription fees are fully earned upon
        payment. In some cases your payment date may change, for example if your
        Payment Method has not successfully settled, when you change your
        subscription plan or if your paid subscription began on a day not
        contained in a given month. Visit the theresellerlife.com website and
        click on the "Account" page to see your next payment date. We may
        authorize your Payment Method in anticipation of subscription or
        service-related charges through various methods, including authorizing
        it up to approximately one month of service as soon as you register. If
        you signed up for ReSeller using your account with a third party as a
        Payment Method, you can find the billing information about your ReSeller
        subscription by visiting your account with the applicable third party.
      </p>
      <p>
        <strong>2.2. Payment Methods.</strong> To use the ReSeller service you
        must provide one or more Payment Methods. You authorize us to charge any
        Payment Method associated with your account in case your primary Payment
        Method is declined or no longer available to us for payment of your
        subscription fee. You remain responsible for any uncollected amounts. If
        a payment is not successfully settled, due to expiration, insufficient
        funds, or otherwise, and you do not cancel your account, we may suspend
        your access to the service until we have successfully charged a valid
        Payment Method. For some Payment Methods, the issuer may charge you
        certain fees, such as foreign transaction fees or other fees relating to
        the processing of your Payment Method. Check with your Payment Method
        service provider for details.
      </p>
      <p>
        <strong>2.3 Updating your Payment Methods.</strong>You can update your
        Payment Methods by going to the "Account" page. We may also update your
        Payment Methods using information provided by the payment service
        providers. Following any update, you authorize us to continue to charge
        the applicable Payment Method(s).
      </p>
      <p>
        <strong>2.4. Cancellation.</strong> You can cancel your ReSeller
        subscription at any time, and you will continue to have access to the
        ReSeller service through the end of your billing period. To cancel, go
        to the "Account" page on our theresellerlife.com website and follow the
        instructions for cancellation. If you cancel your subscription, your
        account will automatically close at the end of your current billing
        period. To see when your account will close, visit the "Account" page.
      </p>
      <p>
        <strong>2.5. Changes to the Price and Subscription Plans.</strong> We
        may change our subscription plans and the price of our service from time
        to time; however, any price changes or changes to your subscription
        plans will apply no earlier than 30 days following notice to you. If you
        do not wish to accept the price change or change to your subscription
        plan, you can cancel your subscription before the change takes effect.
      </p>
      <p>
        <strong>2.6. No Refunds.</strong> Payments are nonrefundable and there
        are no refunds or credits for partially used subscription periods.
        Following any cancellation, however, you will continue to have access to
        the service through the end of your current billing period. At any time,
        and for any reason, we may provide a refund, discount, or other
        consideration to some or all of our members ("credits"). The amount and
        form of such credits, and the decision to provide them, are at our sole
        and absolute discretion. The provision of credits in one instance does
        not entitle you to credits in the future for similar instances, nor does
        it obligate us to provide credits in the future, under any circumstance.
      </p>
      <p>
        <b>3.1.</b> You must be at least 18 years of age to become a member of
        the ReSeller service. Individuals under the age of 18 may only utilize
        the service with the involvement of a parent or legal guardian, under
        such person's account and otherwise subject to these Terms of Use.
      </p>
      <p>
        <b>3.2.</b> The ReSeller service and any content accessed through our
        service are for your personal and non-commercial use only and may not be
        shared with individuals beyond your household unless otherwise allowed
        by your subscription plan. During your ReSeller subscription, we grant
        you a limited, non-exclusive, non-transferable right to access the
        ReSeller service and ReSeller content through the service. Except for
        the foregoing, no right, title or interest shall be transferred to you.
        You agree not to use the service for public performances in any way.
      </p>
      <p>
        <b>3.3.</b> You may access ReSeller service and content primarily within
        the country in which you have established your account and only in
        geographic locations where we offer your subscription plan and have
        licensed such content. The content that may be available will vary by
        geographic location and will change from time to time. The number of
        devices on which you may simultaneously use the service depends on your
        chosen subscription plan and is specified on the "Account" page.
      </p>
      <p>
        <b>3.4.</b> The ReSeller service, including the content, is regularly
        updated. In addition, we continually test various aspects of our
        service, including but not limited to our websites, user interfaces and
        promotional features.
      </p>
      <h6>
        <b>3.5.</b> You agree to use the ReSeller service, including all
        features and functionalities associated therewith, in accordance with
        all applicable laws, rules and regulations, or other restrictions on use
        of the service or content therein. Except as explicitly authorized by
        us, you agree not to:
      </h6>
      <p>
        <b>(i)</b> archive, download, reproduce, distribute, modify, display,
        perform, publish, license, create derivative works from, offer for sale,
        or use content and information contained on or obtained from or through
        the ReSeller service;
      </p>
      <p>
        <b>(ii)</b> circumvent, remove, alter, deactivate, degrade, block,
        obscure or thwart any of the content protections or other elements of
        the ReSeller service, including the graphical user interface, any
        advertising or advertising features, copyright notices, and trademarks;
      </p>
      <p>
        <b>(iii)</b> use any robot, spider, scraper or other automated means to
        access the ReSeller service;
      </p>
      <p>
        <b>(iv)</b> decompile, reverse engineer or disassemble any software or
        other products or processes accessible through the ReSeller service;
      </p>
      <p>
        <b>(v)</b> insert any code or product or manipulate the content of the
        ReSeller service in any way;
      </p>
      <p>
        <b>(vi)</b>use any data mining, data gathering or extraction method;
      </p>
      <p>
        <b>(vii)</b> upload, post, e-mail or otherwise send or transmit any
        material designed to interrupt, destroy or limit the functionality of
        any computer software or hardware or telecommunications equipment
        associated with the ReSeller service, including any software viruses or
        any other computer code, files or programs.
      </p>
      <p>
        <b>3.6</b> We may terminate or restrict your use of our service if you
        violate these Terms of Use or are engaged in illegal or fraudulent use
        of the service.
      </p>
      <p>
        <b>3.7.</b>The quality of the display of the ReSeller content may vary
        from device to device, and may be affected by a variety of factors, such
        as your location, the bandwidth available through and/or speed of your
        Internet connection. Please check with your Internet provider for
        information on possible Internet data usage charges. ReSeller makes no
        representations or warranties about the quality of your viewing
        experience on your display. The time it takes to begin using ReSeller
        content will vary based on a number of factors, including your location,
        available bandwidth at the time, the ReSeller content you have selected
        and the configuration of your ReSeller ready device.
      </p>
      <p>
        <b>3.8.</b> The ReSeller software is developed by, or for, ReSeller and
        may solely be used for authorized access content from ReSeller through
        ReSeller ready devices. This software may vary by device and medium, and
        functionalities may also differ between devices. By using our service,
        you agree to receive, without further notice or prompting, updated
        versions of the ReSeller and related third-party software. If you do not
        accept the foregoing terms, do not use our service.
      </p>
      <p>
        <b>3.9.</b> Passwords and Account Access. You are responsible for any
        activity that occurs through the ReSeller account. By allowing others to
        access the account, you agree that such individuals are acting on your
        behalf and that you are bound by any changes that they may make to the
        account, including but not limited to changes to the subscription plan.
        To help maintain control over the account and prevent any unauthorized
        users from accessing the account, you should maintain control over the
        devices that are used to access the service and not reveal the password
        or details of the Payment Method associated with the account to anyone.
        You agree to provide and maintain accurate information relating to your
        account, including a valid email address so we can send you account
        related notices. We can terminate your account or place your account on
        hold in order to protect you, ReSeller or our partners from identity
        theft or other fraudulent activity. ReSeller is not obligated to credit
        or discount a subscription for holds placed on the account by either a
        representative of ReSeller or by the automated processes of ReSeller. If
        your ReSeller ready device is sold, lost or stolen, please deactivate
        the ReSeller account. If you fail to log out or deactivate your device,
        subsequent users may access the ReSeller service through your account
        and may be able to access certain of your account information.
      </p>
      <h4>Disclaimers of Warranties and Limitations on Liability</h4>
      <p>
        <b>4.1.</b> THE RESELLER SERVICE AND ALL CONTENT AND SOFTWARE ASSOCIATED
        THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE
        RESELLER SERVICE, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITH ALL
        FAULTS AND WITHOUT WARRANTY OF ANY KIND. RESELLER DOES NOT GUARANTEE,
        REPRESENT, OR WARRANT THAT YOUR USE OF THE RESELLER SERVICE WILL BE
        UNINTERRUPTED OR ERROR-FREE. RESELLER SPECIFICALLY DISCLAIMS LIABILITY
        FOR THE USE OF APPLICATIONS, RESELLER READY DEVICES, AND RESELLER
        SOFTWARE (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR SERVICE).
      </p>
      <p>
        <b>4.2.</b>TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT
        SHALL RESELLER, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS,
        DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR
        SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL,
        INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES
        WHATSOEVER.
      </p>
      <p>
        <b>4.3.</b> DEPENDING ON YOUR SUBSCRIPTION PLAN, YOU MAY ENCOUNTER THIRD
        PARTY ADVERTISEMENTS ON THE RESELLER SERVICE. RESELLER DOES NOT ENDORSE
        OR SPONSOR ANY ADVERTISED PRODUCTS OR SERVICES AND TAKES NO
        RESPONSIBILITY FOR THE PRODUCTS OR SERVICES PROVIDED BY ADVERTISERS. ANY
        INTERACTIONS WITH ADVERTISERS, INCLUDING THROUGH ENGAGEMENT WITH
        INTERACTIVE ADVERTISEMENTS, ARE AT YOUR OWN OPTION AND RISK AND YOU
        AGREE THAT RESELLER IS NOT LIABLE FOR ANY DAMAGES THAT YOU MAY INCUR AS
        A RESULT OF SUCH INTERACTIONS.
      </p>
      <p>
        <b>4.4.</b> NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE
        STATUTORY RIGHTS THAT APPLY TO YOU.
      </p>
      <h4>Arbitration Agreement</h4>
      <p>
        <b>5.1.</b> You and ReSeller agree that any dispute, claim or
        controversy arising out of or relating in any way to the ReSeller
        service, these Terms of Use and this Arbitration Agreement, shall be
        determined by binding arbitration or in small claims court. Arbitration
        is more informal than a lawsuit in court. Arbitration uses a neutral
        arbitrator instead of a judge or jury, allows for more limited discovery
        than in court, and is subject to very limited review by courts.
        Arbitrators can award the same damages and relief that a court can award
        and nothing in this Arbitration Agreement shall be interpreted as
        limiting any non-waivable statutory rights. You agree that, by agreeing
        to these Terms of Use, the U.S. Federal Arbitration Act governs the
        interpretation and enforcement of this provision, and that you and
        ReSeller are each waiving the right to a trial by jury or to participate
        in a class action. This arbitration provision shall survive termination
        of this Agreement and the termination of your ReSeller subscription.
      </p>
      <p>
        <b>5.2.</b> If you elect to seek arbitration or file a small claim court
        action, you must first send to ReSeller, by certified mail, a written
        Notice of your claim ("Notice"). The Notice to ReSeller must be
        addressed to: General Counsel, ReSeller, Inc., 10606 West Providence Rd,
        North Chesterfield VA 23236 ("Notice Address"). If ReSeller initiates
        arbitration, it will send a written Notice to the email address used for
        your subscription account. A Notice, whether sent by you or by ReSeller,
        must
      </p>{" "}
      <p>
        {" "}
        <b> (a)</b>describe the nature and basis of the claim or dispute; and
      </p>{" "}
      <p>
        {" "}
        <b>(b)</b> set forth the specific relief sought ("Demand"). If ReSeller
        and you do not reach an agreement to resolve the claim within 30 days
        after the Notice is received, you or ReSeller may commence an
        arbitration proceeding or file a claim in small claims court.
      </p>
      <p>
        <b>5.3.</b> You may download or copy a form Notice and a form to
        initiate arbitration at www.adr.org. If you are required to pay a filing
        fee, after ReSeller receives notice at the Notice Address that you have
        commenced arbitration, ReSeller will reimburse you for your payment of
        the filing fee, unless your claim is for greater than US$100, in which
        event you will be responsible for filing fees.
      </p>
      <p>
        <b>5.4.</b> The arbitration will be governed by the Consumer Arbitration
        Rules (the "AAA Rules") of the American Arbitration Association ("AAA"),
        as modified by this Agreement, and will be administered by the AAA. The
        AAA Rules and Forms are available online at www.adr.org, or by writing
        to the Notice Address. The arbitrator is bound by the terms of this
        Agreement. All issues are for the arbitrator to decide, including issues
        relating to the scope and enforceability of this arbitration agreement.
        Unless ReSeller and you agree otherwise, any arbitration hearings will
        take place in the county (or parish) of your residence. The arbitrator's
        award shall be final and binding on all parties, except
      </p>{" "}
      <p>
        <b>(1)</b> for judicial review expressly permitted by law or
      </p>
      <p>
        <b>(2)</b> if the arbitrator's award includes an award of injunctive
        relief against a party, in which case that party shall have the right to
        seek judicial review of the injunctive relief in a court of competent
        jurisdiction that shall not be bound by the arbitrator's application or
        conclusions of law.
      </p>
      <p>
        <b>5.5.</b> If your claim is for US$1,000 or less, we agree that you may
        choose whether the arbitration will be conducted solely on the basis of
        documents submitted to the arbitrator, through a telephonic hearing, or
        by an in-person hearing as established by the AAA Rules. If your claim
        exceeds US$1,000, the right to a hearing will be determined by the AAA
        Rules. Regardless of the manner in which the arbitration is conducted,
        the arbitrator shall issue a reasoned written decision explaining the
        essential findings and conclusions on which the award is based. If the
        arbitrator issues you an award that is greater than the value of
        ReSeller's last written settlement offer made before an arbitrator was
        selected (or if ReSeller did not make a settlement offer before an
        arbitrator was selected), then ReSeller will pay you the amount of the
        award or US$100, whichever is greater. Except as expressly set forth
        herein, the payment of all filing, administration and arbitrator fees
        will be governed by the AAA Rules.
      </p>
      <p>
        <b>5.6.</b> YOU AND RESELLER AGREE THAT EACH MAY BRING CLAIMS AGAINST
        THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
        PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
        PROCEEDING. Further, unless both you and ReSeller agree otherwise, the
        arbitrator may not consolidate more than one person's claims with your
        claims, and may not otherwise preside over any form of a representative
        or class proceeding. If this specific provision is found to be
        unenforceable, then the entirety of this arbitration provision shall be
        null and void.
      </p>
      <h4>Miscellaneous</h4>
      <p>
        <strong>6.1. Governing Law.</strong> These Terms of Use shall be
        governed by and construed in accordance with the laws of the state of
        Delaware, U.S.A. without regard to conflict of laws provisions. These
        terms will not limit any consumer protection rights that you may be
        entitled to under the mandatory laws of your state of residence.
      </p>
      <p>
        <strong>6.2. Unsolicited Materials.</strong> ReSeller does accept
        unsolicited materials or ideas for ReSeller content, and is not
        responsible for the similarity of any of its content or programming in
        any media to materials or ideas transmitted to ReSeller. Should you send
        any unsolicited materials or ideas, you do so with the understanding
        that no additional consideration of any sort will be provided to you,
        and you are waiving any claim against ReSeller and its affiliates
        regarding the use of such materials and ideas, even if material or an
        idea is used that is substantially similar to the material or idea you
        sent.
      </p>
      <p>
        <strong>6.3. Feedback.</strong> ReSeller is free to use any comments,
        information, ideas, concepts, reviews, or techniques or any other
        material contained in any communication you may send to us ("Feedback"),
        including responses to questionnaires or through postings to the
        ReSeller service, including our websites and user interfaces, worldwide
        and in perpetuity without further compensation, acknowledgement or
        payment to you for any purpose whatsoever including, but not limited to,
        developing, manufacturing and marketing products and creating, modifying
        or improving the ReSeller service. In addition, you agree not to enforce
        any "moral rights" in and to the Feedback, to the extent permitted by
        applicable law.
      </p>
      <p>
        <strong>6.4. Customer Support.</strong> To find more information about
        our service and its features, or if you need assistance with your
        account, please visit the ReSeller Help Group, which is accessible
        through the theresellerlife.com website.
      </p>
      <p>
        <strong>6.5. Survival.</strong> If any provision or provisions of these
        Terms of Use shall be held to be invalid, illegal, or unenforceable, the
        validity, legality and enforceability of the remaining provisions shall
        remain in full force and effect.
      </p>
      <p>
        <strong>6.6. Changes to Terms of Use and Assignment.</strong> ReSeller
        may, from time to time, change these Terms of Use. Such revisions shall
        be effective immediately; provided however, for existing members, such
        revisions shall, unless otherwise stated, be effective 30 days after
        posting. We may assign our agreement with you to any affiliated company
        or to any entity that succeeds to all or substantially all of our
        business or assets related to the applicable ReSeller service.
      </p>
      <p>
        <strong>6.7. Communication Preferences.</strong> We will send you
        information relating to your account (e.g. payment authorizations,
        invoices, changes in password or Payment Method, confirmation messages,
        notices) in electronic form only, for example via emails to your email
        address provided during registration. You agree that any notices,
        agreements, disclosures or other communications that we send to you
        electronically will satisfy any legal communication requirements,
        including that such communications be in writing. Last Updated: July 29,
        2024
      </p>
    </>
  );
};
export default TermsCondition;
