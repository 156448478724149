import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  getproductbykeyword,
  sortApiDataByPrice,
  pageId,
  filterMessage,
  getfilterRequest,
} from "../redux/actions/users";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./loader";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;
const keywordApiRoute = "getItemByKeyword?items_name";
const barcodeApiRoute = "getActiveListingByBarcode?barcodeNumber";

const Filter = () => {
  let dispatch = useDispatch();
  const filtermessage = useSelector((state) => state.getfilterMessage);
  const [isloading, setIsloading] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const Paidshipping_data = useSelector((state) => state.getpaidData);
  const priceRange = useSelector((state) => state.rangeReducer);
  const sortStatus = useSelector((state) => state.sortReducer);
  let { Product_name, bar_code } = useParams();

  function roundUpToNearestMultiple(number, multiple) {
    return Math.ceil(number / multiple) * multiple;
  }

  let shippingfilter = async (shippedFilter) => {
    // setIsloading(true); //
    dispatch(getfilterRequest(shippedFilter));
    dispatch(pageId(1));
    // setTimeout(() => {
    //   setIsloading(false);
    // }, 5000);
  };

  return (
    <>
      {!isloading ? (
        <div>
          <div className="option-wrapper">
            <Dropdown drop="end" className="dropdown">
              <DropdownToggle
                className="btn p-0 sort-btn dropdown-toggle"
                variant=""
              >
                <span>Shipping</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.717"
                  height="32"
                  viewBox="0 0 32.717 32"
                >
                  <path
                    id="filter_alt_FILL0_wght400_GRAD0_opsz48"
                    d="M22,40a1.964,1.964,0,0,1-2-2V26L8.05,10.75a1.513,1.513,0,0,1-.2-1.8A1.643,1.643,0,0,1,9.4,8H38.6a1.643,1.643,0,0,1,1.55.95,1.513,1.513,0,0,1-.2,1.8L28,26V38a1.964,1.964,0,0,1-2,2Zm2-13.8L36,11H12ZM24,26.2Z"
                    transform="translate(-7.642 -8)"
                  />
                </svg>
                {filtermessage.response === "free" ? (
                  <span>&nbsp;{"Free"}</span>
                ) : filtermessage.response === "both" ? (
                  <span>&nbsp;{""}</span>
                ) : filtermessage.response === "paid" ? (
                  <span>&nbsp;{`Paid`}</span>
                ) : (
                  ""
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem("shippingFilter", "True");
                    shippingfilter(0);
                    // Product_details.filter = 0;
                    dispatch(filterMessage("free"));
                  }}
                >
                  Free
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem("shippingFilter", "True");
                    shippingfilter(3);
                    // Product_details.filter = 3;
                    dispatch(filterMessage("paid"));
                  }}
                >
                  Paid
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    localStorage.setItem("shippingFilter", "False");
                    shippingfilter(1);
                    // Product_details.filter = 1;
                    dispatch(filterMessage("both"));
                  }}
                >
                  Both(Free and Paid)
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {/* <button className="btn p-0 sort-btn dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false"><span>Shipping</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.717" height="32"
                            viewBox="0 0 32.717 32">
                            <path id="filter_alt_FILL0_wght400_GRAD0_opsz48"
                                d="M22,40a1.964,1.964,0,0,1-2-2V26L8.05,10.75a1.513,1.513,0,0,1-.2-1.8A1.643,1.643,0,0,1,9.4,8H38.6a1.643,1.643,0,0,1,1.55.95,1.513,1.513,0,0,1-.2,1.8L28,26V38a1.964,1.964,0,0,1-2,2Zm2-13.8L36,11H12ZM24,26.2Z"
                                transform="translate(-7.642 -8)" />
                        </svg>
                        { filtermessage.response === 'free' ? <span>&nbsp;{'Free'}</span> : filtermessage.response === 'both' ? <span>&nbsp;{''}</span> : filtermessage.response ==='paid' ? <span>&nbsp;{`Paid (${Paidshipping_data.response})`}</span>
                        : ''}
                        
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end ">
                        <li><span className="dropdown-item" 
                        onClick={() => {shippingfilter(0);dispatch(filterMessage('free'))}} 
                        type="button">Free</span></li>
                        <li><span className="dropdown-item" 
                        onClick={() => {shippingfilter(3);dispatch(filterMessage('paid'))}} 
                         type="button">Paid</span></li>
                        <li><span className="dropdown-item" 
                        onClick={() => {shippingfilter(1);dispatch(filterMessage('both'))}} 
                         type="button">Both(Free and Paid)</span></li>
                         
                    </ul> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Filter;
