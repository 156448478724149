import * as type from '../types';

const initialState = {
  status: false,
}

const initialStateRender = {
    render: 0,
  }


export function listingReducer(state = initialState, action) {

  switch (action.type) {
    case type.GET_ACTIVE_LISTING_DATA:
      return {
        ...state,status : action.payload
      }
   
    default:
      return state
  }
}

export function componetReRenderReducer(state = initialStateRender, action) {
   
   switch (action.type) {
     case type.GET_COMPONENT_RENDER_STATUS:
       return {
         ...state,render : action.payload
       }
    
     default:
       return state
   }
 }