import React, { useEffect, useState } from "react";
import Mainheader from "../components/mainheader";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../components/loader";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import { checkSubscription } from "../helpers/common";
import DraggableList from "react-draggable-lists";
import { Reorder, useDragControls } from "framer-motion/dist/framer-motion";
import { ReactSortable } from "react-sortablejs";

const FolderPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [updateName, setUpdateName] = useState("");
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [folderNames, setFolderNames] = useState([]);
  const [renderComp, setRenderComps] = useState(0);
  const [isloading, setIsloading] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const navigate = useNavigate();
  const dragControls = useDragControls();
  const formik = useFormik({
    initialValues: {
      foldername: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const data = {
        folderName: values.foldername,
      };
      //folder creation API.
      await axios
        .post(`${process.env.REACT_APP_SERVER_BASE_URI}/createfolder`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === 201) {
            setIsOpen(false);
            setRenderComps(renderComp + 1);
            resetForm({ values: "" });
            toast.success("Folder created successfully");
          } else if (res.data.status === 200) {
            toast.error("Folder already exist!");
          }
          return res;
        })
        .catch((err) => {});
    },

    validationSchema: Yup.object({
      foldername: Yup.string()
        .matches(/^(\w+\s+\.?)*\w+$/, "Please Enter valid folder name!")
        .required("Folder name is required!"),
    }),
  });

  useEffect(() => {
    checkSubscription();
  });

  //get the list of all folders
  let getAllFolders = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/listOfFolder`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setFolderNames(response.data.data);
        setIsloading(true);
      })
      .catch((err) => {
        setIsloading(false);
      });
  };

  let editFolderName = async (foldername, folderID) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/editfolder?folderId=${folderID}&folderName=${foldername}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setIsloading(false);
          setIsFolderOpen(false);
          setRenderComps(renderComp + 1);
          toast.success("Folder name has been change successfully");
        }
        if (response.data.status === 201) {
          setIsloading(false);
          setIsFolderOpen(false);
          setRenderComps(renderComp + 1);
          toast.error("Folder already exist!");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  //delete individual product from folder.
  let deleteProduct = (itemID, folderID) => {
    let status = window.confirm("Are you sure to want to delete this product");
    if (status) {
      setIsloading(true);
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_BASE_URI}/deleteproudctwithfolder?folderId=${folderID}&itemId=${itemID}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setIsloading(false);
            setRenderComps(renderComp + 1);
            toast.success("Product deleted successfully");
          }
        })
        .catch((err) => {
          setIsloading(false);
        });
    }
  };

  const hideModal = () => {
    formik.resetForm();
    setIsOpen(false);
  };

  const hidefolderModal = () => {
    setIsFolderOpen(false);
    navigate("/folders-page");
  };

  React.useEffect(() => {
    getAllFolders();
  }, [renderComp]);

  const deleted = (data) => {
    let status = window.confirm("Are you sure to want to delete this folder");
    if (status) {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_BASE_URI}/deletefolder/?folderId=${data}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setIsloading(false);
            setRenderComps(renderComp + 1);
            toast.success("Folder deleted successfully");
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  const editFolder = () => {
    let status = window.confirm("Are you sure to want to edit this folder");
    if (status) {
      setIsFolderOpen(true);
    }
  };
  var endDate = new Date().toDateString();

  return (
    <>
      <Mainheader />
      <div className="container">
        <div className="fpage_wrapper">
          <main className="main_content_wrapper main_content_wrapper2 custmEdit">
            <div className="row">
              <div className="col-sm-12 text-center mb-4">
                <div
                  className="addfld1 inputsearch_bar-wrapper text-start"
                  onClick={() => setIsOpen(true)}
                >
                  <div className="input-wrapper input-folder">
                    <span className="input_search_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M240 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H176V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H240V80z" />
                      </svg>
                    </span>
                    <h4 className="fldname addfld">Add Folder</h4>
                  </div>
                </div>
              </div>
            </div>
            {isloading ? (
              folderNames && folderNames.length > 0 ? (
                <Reorder.Group
                  axis="y"
                  values={folderNames}
                  onReorder={setFolderNames}
                  style={{ paddingLeft: 0 }}
                >
                  <SwipeableList>
                    {folderNames.map((data, index) => (
                      <div className="swipeBtn">
                        <SwipeableListItem
                          threshold={0.1}
                          swipeLeft={{
                            content: (
                              <span className="right-svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 15 15"
                                >
                                  <g fill="#fff">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                  </g>
                                </svg>
                              </span>
                            ),
                            action: () => {
                              deleted(data._id);
                            },
                          }}
                          swipeRight={{
                            content: (
                              <span className="custom-svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="48"
                                  height="48"
                                  viewBox="0 0 48 48"
                                >
                                  <path
                                    fill="#fff"
                                    d="M36 5.01c-1.795 0-3.59.68-4.95 2.04L8.917 29.185c-.42.42-.728.942-.89 1.515L5.058 41.088a1.5 1.5 0 001.853 1.853l10.39-2.966.003-.002a3.505 3.505 0 001.511-.889L40.95 16.949c2.721-2.72 2.721-7.177 0-9.898A6.976 6.976 0 0036 5.01zm0 2.982a3.982 3.982 0 012.828 1.18 3.976 3.976 0 010 5.656l-1.94 1.94-5.656-5.657 1.94-1.94A3.987 3.987 0 0136 7.993zm-6.889 5.24l5.657 5.657-18.075 18.074a.506.506 0 01-.216.127l-7.793 2.226 2.226-7.795v-.001a.49.49 0 01.127-.215l18.074-18.073z"
                                    transform="matrix(1.16 0 0 1.16 24 24) translate(-24 -24)"
                                  ></path>
                                </svg>
                              </span>
                            ),
                            action: () => {
                              editFolder();
                              setUpdateName(data.folderName);
                              setUpdateId(data._id);
                            },
                          }}
                        >
                          <Reorder.Item key={data} value={data}>
                            <div
                              className="fld-open"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseExample_${index}`}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              <div className="row">
                                <div className="col-12 text-center">
                                  <div className="fsearch_bar-wrapper folder text-start">
                                    <div className="input-wrapper input-folder">
                                      <div className="search_box custmTransform">
                                        <span className="input_search_icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512"
                                          >
                                            <path d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z" />
                                          </svg>
                                        </span>
                                        <h3 className="fldname">
                                          {data.folderName}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Reorder.Item>
                          <div
                            className="collapse"
                            id={`collapseExample_${index}`}
                          >
                            <div className="row accordion-body">
                              {data.product.length > 0
                                ? data.product &&
                                  data.product.map((pdetails) => {
                                    return (
                                      <div className="col-xl-3 col-lg-4 col-md-6 col-6 card-width">
                                        <Link
                                          to={`/product-details/${pdetails.itemId}`}
                                          className="custom-card-wrapper"
                                        >
                                          <div className="custom-card">
                                            <div className="product-img-wrapper">
                                              <img
                                                src={
                                                  pdetails.hasOwnProperty(
                                                    "thumbnailImages"
                                                  )
                                                    ? pdetails
                                                        .thumbnailImages[0]
                                                        .imageUrl
                                                    : pdetails.hasOwnProperty(
                                                        "image"
                                                      )
                                                    ? pdetails.image.imageUrl
                                                    : `${process.env.PUBLIC_URL}/Images/default.jpg`
                                                }
                                                alt="product"
                                                className="img-fluid"
                                              />
                                              <div className="date-str-info">
                                                <span className="date-product">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="36"
                                                    height="41.95"
                                                    viewBox="0 0 36 41.95"
                                                  >
                                                    <path
                                                      id="timer_FILL0_wght400_GRAD0_opsz48"
                                                      d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                                                      transform="translate(-6 -2)"
                                                    />
                                                  </svg>
                                                  {(
                                                    (new Date(
                                                      new Date(endDate)
                                                    ) -
                                                      new Date(
                                                        new Date(
                                                          pdetails.itemCreationDate
                                                        ).toDateString()
                                                      )) /
                                                    (1000 * 60 * 60 * 24)
                                                  ).toFixed() == 0
                                                    ? "Today"
                                                    : `${(
                                                        (new Date(
                                                          new Date(endDate)
                                                        ) -
                                                          new Date(
                                                            new Date(
                                                              pdetails.itemCreationDate
                                                            ).toDateString()
                                                          )) /
                                                        (1000 * 60 * 60 * 24)
                                                      ).toFixed()}`}
                                                </span>
                                                <div className="folder-info">
                                                  <button
                                                    type="button"
                                                    className="my-btn"
                                                    onClick={(e) => {
                                                      deleteProduct(
                                                        pdetails.itemId,
                                                        data._id
                                                      );
                                                      e.preventDefault();
                                                    }}
                                                    title="Delete Product"
                                                  >
                                                    <span className="date-product">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="44"
                                                        height="31.95"
                                                        viewBox="0 0 576 512"
                                                      >
                                                        <path
                                                          id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                                                          transform="translate(-2 -8)"
                                                        />
                                                      </svg>
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card_content">
                                              <h3 className="product-heading mt-2">
                                                <strong>
                                                  <small>
                                                    {pdetails.title}
                                                  </small>
                                                </strong>
                                              </h3>
                                              <div className="product-info">
                                                <span className="shipping-price">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="44"
                                                    height="31.95"
                                                    viewBox="0 0 44 31.95"
                                                  >
                                                    <path
                                                      id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                                      d="M11.2,39.95a5.687,5.687,0,0,1-4.175-1.725A5.687,5.687,0,0,1,5.3,34.05H2V11a2.878,2.878,0,0,1,.9-2.1A2.878,2.878,0,0,1,5,8H33.95v8.35H39.2L46,25.4v8.65H42.45a5.9,5.9,0,0,1-10.075,4.175A5.687,5.687,0,0,1,30.65,34.05H17.1a5.687,5.687,0,0,1-1.725,4.175A5.687,5.687,0,0,1,11.2,39.95Zm0-3A2.9,2.9,0,1,0,9.15,32a2.9,2.9,0,0,0,2.05,4.95ZM5,31.05H6.1A6.625,6.625,0,0,1,8.25,28.9a5.423,5.423,0,0,1,2.9-.8,5.425,5.425,0,0,1,2.925.825A6.967,6.967,0,0,1,16.25,31.05h14.7V11H5Zm31.55,5.9a2.9,2.9,0,1,0-2.05-.85A2.794,2.794,0,0,0,36.55,36.95Zm-2.6-10.2h9.3l-5.55-7.4H33.95ZM18,21.55Z"
                                                      transform="translate(-2 -8)"
                                                    />
                                                  </svg>
                                                  {pdetails.shippingOptions !==
                                                  undefined
                                                    ? pdetails.shippingOptions.map(
                                                        (e) => {
                                                          return e.shippingCost !==
                                                            undefined
                                                            ? e.shippingCost
                                                                .value == 0
                                                              ? "free"
                                                              : `$${parseFloat(
                                                                  e.shippingCost
                                                                    .value
                                                                ).toLocaleString(
                                                                  "en-US"
                                                                )}`
                                                            : "free";
                                                        }
                                                      )
                                                    : "free"}
                                                </span>
                                                <span className="product-cost">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="40.1"
                                                    height="40.05"
                                                    viewBox="0 0 40.1 40.05"
                                                  >
                                                    <path
                                                      id="sell_FILL0_wght400_GRAD0_opsz48"
                                                      d="M20.15,43.15a3.078,3.078,0,0,0,4.35,0L43.25,24.4a2.624,2.624,0,0,0,.675-1.05A3.77,3.77,0,0,0,44.1,22.2V7a2.907,2.907,0,0,0-3-3H25.9a4.267,4.267,0,0,0-1.2.175,2.568,2.568,0,0,0-1.1.675L4.95,23.5a3.081,3.081,0,0,0,0,4.45ZM22.2,41.1,7,25.9,25.9,7H41.1V22.2ZM35.85,14.8a2.581,2.581,0,0,1-1.825-4.425,2.581,2.581,0,1,1,3.65,3.65,2.5,2.5,0,0,1-1.825.775ZM41.1,7Z"
                                                      transform="translate(-4 -4)"
                                                    />
                                                  </svg>
                                                  <small>
                                                    ${pdetails.price.value}
                                                  </small>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    );
                                  })
                                : "No product found!"}
                            </div>
                          </div>
                        </SwipeableListItem>
                      </div>
                    ))}
                  </SwipeableList>
                </Reorder.Group>
              ) : (
                <h2 style={{ textAlign: "center" }}>No record found</h2>
              )
            ) : (
              <Loader />
            )}
          </main>
        </div>
      </div>
      <Modal show={isFolderOpen}>
        <Modal.Header>
          <h4>Edit Folder Details</h4>
        </Modal.Header>

        <Modal.Body>
          <span className="asterisk_input"> </span>{" "}
          <input
            type="text"
            required
            className="form-control fldsearch1"
            id="foldername"
            placeholder="Enter your folder name"
            onChange={(e) => {
              setUpdateName(e.target.value);
            }}
            value={updateName}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              hidefolderModal();
            }}
            className="btn btn-danger"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              editFolderName(updateName, updateId);
            }}
            className="btn btn-success"
          >
            Edit & Save
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <h4>Folder Details</h4>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <span className="asterisk_input"> </span>{" "}
            <input
              type="text"
              className="form-control fldsearch1"
              id="foldername"
              placeholder="Enter your folder name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.foldername}
            />
            {formik.touched.foldername && formik.errors.foldername ? (
              <div className="error">{formik.errors.foldername}</div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={hideModal}
              className="btn btn-danger"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-success">
              Create folder
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer autoClose={8000} position="top-center" />
    </>
  );
};
export default FolderPage;
