import { call, put,} from 'redux-saga/effects'
import * as types from '../types/index'
import { callLogin, callprofile,} from '../../services/userService';
import { getproducts, getsoldproducts } from '../../services/productService'

export function* LoginUsersaga(val, response) {
   try {
      response = yield call(callLogin, val.value)
      yield put({ type: types.GET_USER_LOGIN_SUCCESS, response, error: false });
    
   }
   catch (error) {
      let errorResponse = error
      yield put({
         type: types.GET_USER_LOGIN_FAILED,
         response: errorResponse.response,
         error: true
      });
     
      return false;
   }

}

export function* ProfileUsersaga(val, response) {
   try {
      response = yield call(callprofile, val.value)
      yield put({ type: types.GET_COMPLETE_PROFILE_SUCCESS, response, error: false });
      
   }
   catch (error) {
      let errorResponse = error
      yield put({
         type: types.GET_COMPLETE_PROFILE_FAILED,
         response: errorResponse.response,
         error: true
      });
      
      return false;
   }

}

export function* Productbykeywordsaga(value, response, apiType) {
   try {
      
         response = yield call(getproducts, value.payload, value.sort, value.pagination, value.priceRange, value.apiType,value.filter,value.condition)
      
      yield put({ type: types.GET_PRODUCTS_SUCCESS, response, error: false });
      
   }
   catch (error) {
      let errorResponse = error
      yield put({
         type: types.GET_PRODUCTS_FAILED,
         response: errorResponse.response,
         error: true
      });
      
      return false;
   }

}

export function* Soldproductbykeywordsaga(value, response, sort) {
   try {
      
      response = yield call(getsoldproducts, value.payload, value.sort, value.pagination, value.priceRange)
      yield put({ type: types.GET_SOLD_PRODUCTS_SUCCESS, response, error: false });
     
   }
   catch (error) {
      let errorResponse = error
      yield put({
         type: types.GET_SOLD_PRODUCTS_FAILED,
         response: errorResponse.response,
         error: true
      });
      
      return false;
   }

}


