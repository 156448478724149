import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { State } from "country-state-city";
import Select from "react-select";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatPhoneNumber } from "react-phone-number-input";
import Loader from "../components/loader";

const Profile = () => {
  const [selectedState, setSelectedState] = useState(null);
  const [coverImage, setCoverImage] = useState();
  const profiledata = useSelector((state) => state.profileReducer);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [phoneshow, setPhoneshow] = useState(false);
  const [stateshow, setStateshow] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/getprofile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setProfileData(res.data.data);
        setPhoneNumber(res.data.data.phone);
        setSelectedState(res.data.data.state);
        setIsloading(true);
      })
      .catch((err) => {});
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: profileData.name,
      email: profileData.email,
      businessname: profileData.businessname,
      phone: profileData.phone,
      streetaddress: profileData.streetaddress,
      countryname: "United States of America",
      state: profileData.isoCode,
      city: profileData.city,
      zipcode: profileData.zipcode,
      // ebayUserId: "",
    },

    onSubmit: async (values) => {
      if (selectedState !== null) {
        if (phoneNumber !== null) {
          const data = {
            name: values.name,
            email: values.email,
            businessname: values.businessname,
            phone: phoneNumber,
            streetaddress: values.streetaddress,
            countryname: values.countryname,
            state: selectedState.isoCode,
            city: values.city,
            zipcode: values.zipcode.toString(),
            // ebayUserId: values.ebayUserId
          };

          axios
            .post(
              `${process.env.REACT_APP_SERVER_BASE_URI}/Profileupdate`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              setBtnDisabled(true);
              toast.success("Profile updated successfully");
              setTimeout(() => {
                navigate("/home");
              }, 1000);
            })
            .catch((err) => {});
        } else {
          setPhoneshow(true);
        }
      } else {
        setStateshow(true);
      }
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z ]{3,40}$/, "Please Enter valid name!")
        .required("Name is required!"),
      email: Yup.string()
        .email("Email must be a valid email address!")
        .required("Email is required!"),
      // businessname: Yup.string().required('Business name is required!'),
      businessname: Yup.string().matches(
        /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!]{2,}$/,
        "Please Enter valid business name!"
      ),
      streetaddress: Yup.string().required("Address is required!"),
      countryname: Yup.string().required("Please select your country!"),
      city: Yup.string().required("City Name is required!"),
      zipcode: Yup.string()
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Invalid Zip Code!")
        .required("Zip Code is required!"),
      // ebayUserId: Yup.string().matches(/^[A-Za-z0-9]{3,40}$/, 'Please enter valid ebay user Id!')
    }),
  });

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString.target.value).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      setPhoneNumber(
        [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
      );
    } else {
      setPhoneNumber(null);
    }
  }
  const handlechange = () => {
    navigate("/home");
  };

  return (
    <>
      {isloading ? (
        <div>
          <div className="createProfile_Sec">
            <div className="container">
              <div className="profileMain_Box">
                <div className="createProfile_Box1 registrationsection cstmscroll">
                  <div className="px-lg-5 createProfile_FormBox">
                    <img
                      src="Images/Logo.png"
                      className="img-fluid d-none d-md-block"
                      alt="Site Logo"
                    />

                    {/* <!-- Mobile Logo --> */}
                    <div className="mobile_log_wrapper d-md-none text-center mb-3">
                      <img src="Images/mobile-logo.png" className="img-fluid" />
                    </div>
                    <h1 className="mb-md-0 ">User Profile</h1>
                    <button
                      type="button"
                      className="btn profile-btn btn-outline-success mt-2"
                      onClick={handlechange}
                    >
                      Back
                    </button>
                    <span className="d-none d-md-block mb-2">User Details</span>
                    <form
                      className="signUpForm_box p-0"
                      onSubmit={formik.handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="mb-3 customInput_Box mt-3">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="error">{formik.errors.name}</div>
                        ) : null}
                      </div>

                      <div className="mb-3 customInput_Box">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="mb-3 customInput_Box">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          id="businessname"
                          name="businessname"
                          placeholder="Business Name (Optional)"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.businessname}
                        />
                        {formik.touched.businessname &&
                        formik.errors.businessname ? (
                          <div className="error">
                            {formik.errors.businessname}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3 customInput_Box">
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          autoComplete="off"
                          name="phone"
                          placeholder="(123) 456-7890*"
                          value={phoneNumber}
                          onChange={(event) => {
                            formatPhoneNumber(event);
                            setPhoneshow(false);
                          }}
                        />
                        {phoneshow && (
                          <div className="error">
                            Please enter a valid phone number!
                          </div>
                        )}
                      </div>

                      <div className="mb-3 customInput_Box">
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          id="streetaddress"
                          name="streetaddress"
                          placeholder="Street Address*"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetaddress}
                        />
                        {formik.touched.streetaddress &&
                        formik.errors.streetaddress ? (
                          <div className="error">
                            {formik.errors.streetaddress}
                          </div>
                        ) : null}
                      </div>

                      <div className="mb-3 customInput_Box">
                        <div className="row">
                          <div className="col-md-7 mb-3 mb-md-0">
                            <select
                              id="countryname"
                              name="countryname"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.countryname}
                            >
                              <option value="" selected disabled hidden>
                                Choose Your Country
                              </option>
                              <option value="United States of America">
                                United States of America
                              </option>
                            </select>
                            {formik.touched.countryname &&
                            formik.errors.countryname ? (
                              <div className="error">
                                {formik.errors.countryname}
                              </div>
                            ) : null}
                          </div>

                          <div className="col-md-5 custom-select">
                            <Select
                              options={State.getStatesOfCountry("US")}
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              id="state"
                              name="state"
                              className="form-control multiselectcstm"
                              placeholder="State*..."
                              onBlur={formik.handleBlur}
                              onChange={(item) => {
                                setSelectedState(item);
                                setStateshow(false);
                              }}
                              value={State.getStateByCodeAndCountry(
                                selectedState,
                                "US"
                              )}
                            />
                            {stateshow && (
                              <div className="error">Please select State</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 customInput_Box">
                        <div className="row">
                          <div className="col-md-8 mb-3 mb-md-0">
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              autoComplete="off"
                              name="city"
                              placeholder="City Name*"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="error">{formik.errors.city}</div>
                            ) : null}
                          </div>

                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              name="zipcode"
                              autoComplete="off"
                              placeholder="Zip Code*"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipcode}
                            />
                            {formik.touched.zipcode && formik.errors.zipcode ? (
                              <div className="error">
                                {formik.errors.zipcode}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="signUpForm_BTN">
                        <button
                          className="btn"
                          type="submit"
                          disabled={btnDisabled}
                        >
                          Edit & Save{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="createProfile_Box1 createProfile_Box2"></div>
              </div>
            </div>
          </div>
          <ToastContainer autoClose={8000} position="top-center" />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Profile;
