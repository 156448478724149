import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/loader";
import AdminHeader from "../components/adminHeader";
import AdminSidebar from "../components/adminSidebar";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

const Dashboard = () => {
  const [countApi, setCountApi] = useState([]);
  const [totalSearch, setTotalSearch] = useState("0");
  const [totalUser, setTotaluser] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();

  //get the no. of times API hit for admin dashboard
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/actionCountforadmin`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCountApi(res.data.data);
        setTotalSearch(res.data.totalcount);
        setIsloading(true);
      })
      .catch((err) => {
        throw err;
      });
    allUser();
  }, []);

  //get all user details for admin dashboard.
  let allUser = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/allUser`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTotaluser(res.data.data);
        setIsloading(true);
      })
      .catch((err) => {
        throw err;
      });
  };

  //delete user API.
  let deleteUser = (userId) => {
    confirmAlert({
      title: "",
      message: "Do you really want to delete this customer ?",
      buttons: [
        {
          label: "Yes, I want to delete",
          onClick: () => {
            axios
              .delete(
                `${process.env.REACT_APP_SERVER_BASE_URI}/deleteuserbysdmin?userID=${userId}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === 200) {
                  toast.success("User deleted successfully");
                  allUser();
                }
              })
              .catch((err) => {
                throw err;
              });
          },
        },
        {
          label: "Cancel",
          onClick: () => navigate("/admin/dashboard"),
        },
      ],
    });
  };

  return (
    <>
      <div className="dashboard-section">
        <div className="bg-pattern"></div>
        {/* <!----- Sidebar Section Start -----> */}
        <AdminSidebar />
        {/* <!----- Dashboard Body Start -----> */}
        {isloading ? (
          <div className="dashboard-body">
            <AdminHeader />
            <div className="page-content">
              <div className="page-head-sec"></div>
              <div className="row cards">
                <div className="col-md">
                  <div className="card-1 card-border-radious">
                    <div className="card-body">
                      <p className="fw-bold">Search by Text</p>
                      <h3>
                        {countApi !== undefined
                          ? countApi.map((e) => {
                              if (e.APItype == "Text Search") {
                                return e.count;
                              }
                            })
                          : "0"}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="card-2 card-border-radious">
                    <div className="card-body">
                      <p className="fw-bold">Search by Barcode</p>
                      <h3>
                        {countApi !== undefined
                          ? countApi.map((e) => {
                              if (e.APItype == "Bar Code ") {
                                return e.count;
                              }
                            })
                          : "0"}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="card-3 card-border-radious">
                    <div className="card-body">
                      <p className="fw-bold">Total Searches</p>
                      <h3>{totalSearch ? totalSearch : "0"}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="card-4 card-border-radious">
                    <div className="card-body">
                      <p className="fw-bold">Total Review Saved</p>
                      <h3>
                        {countApi !== undefined
                          ? countApi.map((e) => {
                              if (e.APItype == "Comp Histoty") {
                                return e.count;
                              }
                            })
                          : "0"}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md ">
                  <div className="card-5 card-border-radious">
                    <div className="card-body">
                      <p className="fw-bold">Total Drafts</p>
                      <h3>0</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-container mt-3">
                <div className="data-head-sec">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <h4 className="data-heading">Customer Details</h4>
                    </div>
                  </div>
                </div>
                <div className="table-wrapper">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped customer-table"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Acc. Creation Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalUser.length > 0 ? (
                          totalUser.map((userDetails) => {
                            return (
                              <tr>
                                <td>{userDetails.name}</td>
                                <td>{userDetails.email}</td>
                                <td>{userDetails.phone}</td>
                                <td>
                                  {moment(userDetails.createdAt).format("ll")}
                                </td>
                                <td>
                                  <div className="action-btn">
                                    <button
                                      className="delete-btn"
                                      title="Delete Customer"
                                      onClick={() => {
                                        deleteUser(userDetails._id);
                                      }}
                                    >
                                      <i className="bx bxs-trash-alt"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <h4 style={{ textAlign: "center" }}>No data found</h4>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ToastContainer autoClose={8000} position="top-center" />
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Dashboard;
