import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

const AdminHeader = () => {
  const adminName = localStorage.getItem("name");
  const navigate = useNavigate();
  const handlelogout = () => {
    localStorage.clear();
    navigate("/admin");
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="user-profile admin-profile">
          <div className="user-img">
            <img src="./assets/images/user.jpg" alt="" className="w-100" />
          </div>
          <DropdownButton
            className="dropdown"
            variant=""
            title={adminName.charAt(0).toUpperCase() + adminName.slice(1)}
          >
            <DropdownItem className="dropdown-item">
              <Link to="/change-password">Change Password</Link>
            </DropdownItem>
            <DropdownItem
              className="dropdown-item my-btn"
              onClick={(e) => {
                handlelogout(e);
              }}
            >
              Log out
            </DropdownItem>
          </DropdownButton>
          {/* <div className="dropdown">
                                <button className="dropdown-toggle" data-bs-toggle="dropdown">
                                    {adminName.charAt(0).toUpperCase() + adminName.slice(1)}
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="dropdown-item" to="/change-password">Change Password</Link>
                                    </li>
                                    <li>
                                        <button className="dropdown-item my-btn" onClick={(e) => { handlelogout(e) }}>Log out</button>
                                    </li>
                                </ul>
                            </div> */}
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
