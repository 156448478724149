import * as type from "../types";

export function userSignin(value) {
  return {
    type: type.GET_USER_LOGIN,
    value,
  };
}

export function completeProfile(value) {
  return {
    type: type.GET_COMPLETE_PROFILE,
    value,
  };
}

export function getproductbykeyword(
  data,
  sort,
  pagination,
  priceRange,
  apiType,
  filter,
  condition
) {
  return {
    type: type.GET_PRODUCTS_REQUESTED,
    payload: data,
    sort: sort,
    pagination: pagination,
    priceRange: priceRange,
    apiType: apiType,
    filter: filter,
    condition: condition,
  };
}

export function getsoldproductbykeyword(data, sort, pagination, priceRange) {
  return {
    type: type.GET_SOLD_PRODUCTS_REQUESTED,
    payload: data,
    sort: sort,
    pagination: pagination,
    priceRange: priceRange,
  };
}

export function sortApiDataByPrice(data) {
  return {
    type: type.SORT_PRODUCT,
    payload: data,
  };
}

export const onMinSliderChange = (data) => {
  return {
    type: type.GET_UPDATEA,
    payload: data,
  };
};

export const onMaxSliderChange = (data) => {
  return {
    type: type.GET_UPDATEB,
    payload: data,
  };
};

export const singleProductData = (data) => {
  return {
    type: "SINGLE_PRODUCT_DATA",
    payload: data,
  };
};

export function subscriptionId(data) {
  return {
    type: type.GET_SUBSCRIPTION_ID,
    payload: data,
  };
}

export function pageId(data) {
  return {
    type: type.GET_PAGE_ID,
    payload: data,
  };
}

export function roleId(data) {
  return {
    type: type.GET_ROLE_ID,
    payload: data,
  };
}

export function sortMessage(data) {
  return {
    type: type.GET_SORT,
    payload: data,
  };
}

export function getfilterRequest(data) {
  return {
    type: type.GET_FILTER_REQUESTED,
    payload: data,
  };
}

export function getconditionRequest(data) {
  return {
    type: type.GET_CONDITION_REQUESTED,
    payload: data,
  };
}

export function filterMessage(data) {
  return {
    type: type.GET_FILTER_MESSAGE,
    payload: data,
  };
}

export function conditionMessage(data) {
  return {
    type: type.GET_CONDIION_MESSAGE,
    payload: data,
  };
}

export function cancelSubscribed(data) {
  return {
    type: type.GET_CANCEL_SUBSCRIPTION,
    payload: data,
  };
}

export function searchKeyword(data) {
  return {
    type: type.GET_SEARCH_KEYWORD,
    payload: data,
  };
}

export function searchBarcode(data) {
  return {
    type: type.GET_SEARCH_BARCODE,
    payload: data,
  };
}

export function paidShipping(data) {
  return {
    type: type.GET_PAID_DATA,
    payload: data,
  };
}

export function perPageData(data) {
  return {
    type: type.GET_PERPAGE_DATA,
    payload: data,
  };
}

export function backButton(data) {
  return {
    type: type.GET_BACK_DATA,
    payload: data,
  };
}

export const updatedData = (data) => {
  return {
    type: type.UPDATED_DATA,
    payload: data,
  };
};

export const getminPageNumber = (data) => {
  return {
    type: type.MINPAGE_NUMBER,
    payload: data,
  };
};

export const getmaxPageNumber = (data) => {
  return {
    type: type.MAXPAGE_NUMBER,
    payload: data,
  };
};

export function subscriptionType(data) {
  return {
    type: type.GET_TYPE_SUBSCRIPTION,
    payload: data,
  };
}
