import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers/index.js";
import rootSaga from "../saga/index";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getupdatedData } from "../reducers/usersReducer.js";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "roleData",
  storage: storage,
  whitelist: [
    "getroleId",
    "getcancelSubscription",
    "getproductbykeywordReducer",
    "getsubscriptionType",
    "getupdatedData",
    "getperPageData",
    "getsearchKeyword",
    "getsearchBarcode",
    "getsortMessage",
    "getfilterMessage",
    "getfilterReducer",
    "getconditionMessage",
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = compose(
  applyMiddleware(sagaMiddleware),
  typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
    ? (a) => a
    : window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
)(createStore)(persistedReducer);

sagaMiddleware.run(rootSaga);

export default store;
