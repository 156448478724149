export const GET_USER_LOGIN = "GET_USER_LOGIN";
export const GET_USER_LOGIN_SUCCESS = "GET_USER_LOGIN_SUCCESS";
export const GET_USER_LOGIN_FAILED = "GET_USER_LOGIN_FAILED";

export const GET_COMPLETE_PROFILE = "GET_COMPLETE_PROFILE";
export const GET_COMPLETE_PROFILE_SUCCESS = "GET_COMPLETE_PROFILE_SUCCESS";
export const GET_COMPLETE_PROFILE_FAILED = "GET_COMPLETE_PROFILE_FAILED";

export const GET_PRODUCTS_REQUESTED = "GET_PRODUCTS_REQUESTED";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";

export const GET_SOLD_PRODUCTS_REQUESTED = "GET_SOLD_PRODUCTS_REQUESTED";
export const GET_SOLD_PRODUCTS_SUCCESS = "GET_SOLD_PRODUCTS_SUCCESS";
export const GET_SOLD_PRODUCTS_FAILED = "GET_SOLD_PRODUCTS_FAILED";

export const GET_LOADING_REQUESTED = "GET_LOADING_REQUESTED";
export const GET_LOADING_SUCCESS = "GET_LOADING_SUCCESS";
export const GET_LOADING_FAILED = "GET_LOADING_FAILED";

export const SORT_PRODUCT = "SORT_PRODUCT";
export const GET_UPDATEA = "GET_UPDATEA";
export const GET_UPDATEB = "GET_UPDATEB";

export const GET_ACTIVE_LISTING_DATA = "GET_ACTIVE_LISTING_DATA";
export const GET_COMPONENT_RENDER_STATUS = "GET_COMPONENT_RENDER_STATUS";

export const GET_SUBSCRIPTION_ID = "GET_SUBSCRIPTION_ID";

export const GET_PAGE_ID = "GET_PAGE_ID";
export const GET_ROLE_ID = "GET_ROLE_ID";
export const GET_SORT = "GET_SORT";
export const GET_FILTER_MESSAGE = "GET_FILTER_MESSAGE";
export const GET_CONDIION_MESSAGE = "GET_CONDIION_MESSAGE";
export const GET_CANCEL_SUBSCRIPTION = "GET_CANCEL_SUBSCRIPTION";
export const GET_SEARCH_KEYWORD = "GET_SEARCH_KEYWORD";
export const GET_SEARCH_BARCODE = "GET_SEARCH_BARCODE";
export const GET_PAID_DATA = "GET_PAID_DATA";
export const GET_PERPAGE_DATA = "GET_PERPAGE_DATA";
export const GET_BACK_DATA = "GET_BACK_DATA";
export const CHECK_RENDER_COMPONENT = "CHECK_RENDER_COMPONENT";
export const UPDATED_DATA = "UPDATED_DATA";
export const MAXPAGE_NUMBER = "MAXPAGE_NUMBER";
export const MINPAGE_NUMBER = "MINPAGE_NUMBER";
export const GET_TYPE_SUBSCRIPTION = "GET_TYPE_SUBSCRIPTION";
export const GET_FILTER_REQUESTED = "GET_FILTER_REQUESTED";
export const GET_CONDITION_REQUESTED = "GET_CONDITION_REQUESTED";
