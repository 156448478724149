import axios from 'axios';

const apiUrl = process.env.REACT_APP_SERVER_BASE_URI;


// user Registration API
export let callprofile = async (response) => {
    return (await axios.post(`${apiUrl}/sellerregistration`, response, {
        headers: { 'content-type': 'multipart/form-data' }
    })
        .then((response) => response)
        .catch((error) => { throw error }))
}


// user Login API
export let callLogin = async (response) => {
    return (await axios.post(`${apiUrl}/sellerlogin`, response)
        .then((response) => response)
        .catch((error) => { throw error }))
}



