import React, { useEffect, useState } from "react";
import Mainheader from "../components/mainheader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Loader from "../components/loader";
import { checkSubscription } from "../helpers/common";
import { useDispatch, useSelector } from "react-redux";
import { backButton, pageId } from "../redux/actions/users";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const ProductDetails = (props) => {
  const { item_id } = useParams();
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const [folderNames, setFolderNames] = useState([]);
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState({});
  const [frameHeight, setHeight] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const page_data = localStorage.getItem("currentpage");
  const handlechange = () => {
    navigate(-1);
    dispatch(pageId(page_data));
    dispatch(backButton(false));
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/getItemByID?item_ids=${item_id}`
      )
      .then((response) => {
        setProduct(response.data.data);
      })
      .catch((err) => {
        throw err;
      });
    checkSubscription();
  }, []);

  let getAllFolders = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URI}/listOfFolder`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setShow(false);
          setFolderNames(response.data.data);
        } else {
          setShow(false);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  let saveProduct = (folderID, productdata) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/saveproudctwithfolder`,
        {
          folderId: folderID,
          product: productdata,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          toast.success("Product saved successfully");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  var endDate = new Date().toDateString();

  return (
    <>
      <Mainheader />
      <div className="container">
        <div className="page_wrapper">
          <main className="main_content_wrapper">
            <button
              type="button"
              className="btn back-btn"
              onClick={handlechange}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.2"
                height="24.1"
                viewBox="0 0 14.2 24.1"
              >
                {" "}
                <path
                  id="chevron_left_FILL0_wght400_GRAD0_opsz48"
                  d="M28.05,36,16,23.95,28.05,11.9l2.15,2.15-9.9,9.9,9.9,9.9Z"
                  transform="translate(-16 -11.9)"
                />
              </svg>{" "}
              Back
            </button>
            {Object.keys(product).length > 0 ? (
              <div className="row">
                <div
                  className=" col-lg-6 col-md-12 d-lg-none product-detailsinfo product-detailsbody"
                  id="prodct-title"
                >
                  <span className="">{product.title}</span>
                </div>
                <div className="col-lg-6 col-md-12 custom-carousel">
                  <Carousel showIndicators={false} dynamicHeight={false}>
                    <div className="img-adjust set-time ">
                      <img
                        src={
                          product.hasOwnProperty("image")
                            ? product.image.imageUrl
                            : `${process.env.PUBLIC_URL}/Images/default.jpg`
                        }
                      />
                      <div className="date-product">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="41.95"
                          viewBox="0 0 36 41.95"
                        >
                          <path
                            id="timer_FILL0_wght400_GRAD0_opsz48"
                            d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                            transform="translate(-6 -2)"
                          ></path>
                        </svg>
                        {(
                          (new Date(new Date(endDate)) -
                            new Date(
                              new Date(product.itemCreationDate).toDateString()
                            )) /
                          (1000 * 60 * 60 * 24)
                        ).toFixed() == 0
                          ? "Today"
                          : `${(
                              (new Date(new Date(endDate)) -
                                new Date(
                                  new Date(
                                    product.itemCreationDate
                                  ).toDateString()
                                )) /
                              (1000 * 60 * 60 * 24)
                            ).toFixed()}`}
                      </div>
                      <Dropdown drop="end" className="folder-details">
                        <DropdownToggle
                          variant=""
                          className="folder-btn no-caret"
                        >
                          <span className="foldericon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="31.95"
                              viewBox="0 0 576 512"
                            >
                              <path
                                id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                transform="translate(-2 -8)"
                              />
                            </svg>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {folderNames.length > 0 ? (
                            folderNames.map((fdetail) => {
                              return (
                                <DropdownItem
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    saveProduct(fdetail._id, product);
                                    e.preventDefault();
                                  }}
                                >
                                  {fdetail.folderName}
                                </DropdownItem>
                              );
                            })
                          ) : (
                            <DropdownItem className="dropdown-item">
                              {" "}
                              {show ? "Loading ..." : "Folders not found!"}
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                      {/* <button
                          type="button"
                          className="folder-btn"
                          onClick={() => {
                            getAllFolders();
                            setShow(true);
                          }}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Save Product"
                        >
                          <span className="foldericon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="44"
                              height="31.95"
                              viewBox="0 0 576 512"
                            >
                              <path
                                id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                transform="translate(-2 -8)"
                              />
                            </svg>
                          </span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          {folderNames.length > 0 ? (
                            folderNames.map((fdetail) => {
                              return (
                                <li>
                                  <span
                                    className="dropdown-item"
                                    onClick={() => {
                                      saveProduct(fdetail._id, product);
                                    }}
                                  >
                                    {fdetail.folderName}
                                  </span>
                                </li>
                              );
                            })
                          ) : (
                            <li>
                              <span className="dropdown-item">
                                {" "}
                                {show ? "Loading ..." : "Folders not found!"}
                              </span>
                            </li>
                          )}
                        </ul> */}
                    </div>
                    {product.hasOwnProperty("additionalImages") ? (
                      product.additionalImages.map((Imgpath, index) => (
                        <div key={index} className="img-adjust set-time">
                          <img src={Imgpath.imageUrl} />
                          <div className="date-product">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="41.95"
                              viewBox="0 0 36 41.95"
                            >
                              <path
                                id="timer_FILL0_wght400_GRAD0_opsz48"
                                d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                                transform="translate(-6 -2)"
                              ></path>
                            </svg>
                            {(
                              (new Date(new Date(endDate)) -
                                new Date(
                                  new Date(
                                    product.itemCreationDate
                                  ).toDateString()
                                )) /
                              (1000 * 60 * 60 * 24)
                            ).toFixed() == 0
                              ? "Today"
                              : `${(
                                  (new Date(new Date(endDate)) -
                                    new Date(
                                      new Date(
                                        product.itemCreationDate
                                      ).toDateString()
                                    )) /
                                  (1000 * 60 * 60 * 24)
                                ).toFixed()}`}
                          </div>
                          <Dropdown drop="end" className="folder-details">
                            <DropdownToggle
                              variant=""
                              className="folder-btn no-caret"
                            >
                              <span className="foldericon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="31.95"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                    d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                    transform="translate(-2 -8)"
                                  />
                                </svg>
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {folderNames.length > 0 ? (
                                folderNames.map((fdetail) => {
                                  return (
                                    <DropdownItem
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        saveProduct(fdetail._id, product);
                                        e.preventDefault();
                                      }}
                                    >
                                      {fdetail.folderName}
                                    </DropdownItem>
                                  );
                                })
                              ) : (
                                <DropdownItem className="dropdown-item">
                                  {" "}
                                  {show ? "Loading ..." : "Folders not found!"}
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                          {/* <div className="folder-details">
                            <button
                              type="button"
                              className="folder-btn"
                              onClick={() => {
                                getAllFolders();
                                setShow(true);
                              }}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              title="Save Product"
                            >
                              <span className="foldericon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="31.95"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                    d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                    transform="translate(-2 -8)"
                                  />
                                </svg>
                              </span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                              {folderNames.length > 0 ? (
                                folderNames.map((fdetail) => {
                                  return (
                                    <li>
                                      <span
                                        className="dropdown-item"
                                        onClick={() => {
                                          saveProduct(fdetail._id, product);
                                        }}
                                      >
                                        {fdetail.folderName}
                                      </span>
                                    </li>
                                  );
                                })
                              ) : (
                                <li>
                                  <span className="dropdown-item">
                                    {" "}
                                    {show
                                      ? "Loading ..."
                                      : "Folders not found!"}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div> */}
                        </div>
                      ))
                    ) : product.hasOwnProperty("primaryItemGroup") &&
                      product.primaryItemGroup.hasOwnProperty(
                        "itemGroupAdditionalImages"
                      ) ? (
                      product.primaryItemGroup.itemGroupAdditionalImages.map(
                        (imagepath, index) => (
                          <div key={index} className="img-adjust set-time">
                            <img src={imagepath.imageUrl} />
                            <div className="date-product">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="36"
                                height="41.95"
                                viewBox="0 0 36 41.95"
                              >
                                <path
                                  id="timer_FILL0_wght400_GRAD0_opsz48"
                                  d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                                  transform="translate(-6 -2)"
                                ></path>
                              </svg>
                              {(
                                (new Date(new Date(endDate)) -
                                  new Date(
                                    new Date(
                                      product.itemCreationDate
                                    ).toDateString()
                                  )) /
                                (1000 * 60 * 60 * 24)
                              ).toFixed() == 0
                                ? "Today"
                                : `${(
                                    (new Date(new Date(endDate)) -
                                      new Date(
                                        new Date(
                                          product.itemCreationDate
                                        ).toDateString()
                                      )) /
                                    (1000 * 60 * 60 * 24)
                                  ).toFixed()}`}
                            </div>
                            <Dropdown drop="end" className="folder-details">
                              <DropdownToggle
                                variant=""
                                className="folder-btn no-caret"
                              >
                                <span className="foldericon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="31.95"
                                    viewBox="0 0 576 512"
                                  >
                                    <path
                                      id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                      d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                      transform="translate(-2 -8)"
                                    />
                                  </svg>
                                </span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {folderNames.length > 0 ? (
                                  folderNames.map((fdetail) => {
                                    return (
                                      <DropdownItem
                                        className="dropdown-item"
                                        onClick={(e) => {
                                          saveProduct(fdetail._id, product);
                                          e.preventDefault();
                                        }}
                                      >
                                        {fdetail.folderName}
                                      </DropdownItem>
                                    );
                                  })
                                ) : (
                                  <DropdownItem className="dropdown-item">
                                    {" "}
                                    {show
                                      ? "Loading ..."
                                      : "Folders not found!"}
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </Dropdown>
                            {/* <div className="folder-details">
                              <button
                                type="button"
                                className="folder-btn"
                                onClick={() => {
                                  getAllFolders();
                                  setShow(true);
                                }}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                title="Save Product"
                              >
                                <span className="foldericon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="44"
                                    height="31.95"
                                    viewBox="0 0 576 512"
                                  >
                                    <path
                                      id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                      d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                      transform="translate(-2 -8)"
                                    />
                                  </svg>
                                </span>
                              </button>
                              <ul className="dropdown-menu dropdown-menu-end">
                                {folderNames.length > 0 ? (
                                  folderNames.map((fdetail) => {
                                    return (
                                      <li>
                                        <span
                                          className="dropdown-item"
                                          onClick={() => {
                                            saveProduct(fdetail._id, product);
                                          }}
                                        >
                                          {fdetail.folderName}
                                        </span>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <li>
                                    <span className="dropdown-item">
                                      {" "}
                                      {show
                                        ? "Loading ..."
                                        : "Folders not found!"}
                                    </span>
                                  </li>
                                )}
                              </ul>
                            </div> */}
                          </div>
                        )
                      )
                    ) : (
                      <div className="img-adjust set-time">
                        <img
                          src={
                            product.hasOwnProperty("image")
                              ? product.image.imageUrl
                              : `${process.env.PUBLIC_URL}/Images/default.jpg`
                          }
                        />
                        <div className="date-product">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="41.95"
                            viewBox="0 0 36 41.95"
                          >
                            <path
                              id="timer_FILL0_wght400_GRAD0_opsz48"
                              d="M18,5V2H30V5Zm4.5,22.35h3V15.85h-3ZM24,43.95a17.292,17.292,0,0,1-6.975-1.425,18.267,18.267,0,0,1-9.6-9.6,17.783,17.783,0,0,1,0-13.95,18.267,18.267,0,0,1,9.6-9.6A17.292,17.292,0,0,1,24,7.95a17.53,17.53,0,0,1,6.3,1.125A17.346,17.346,0,0,1,35.55,12.2L38.1,9.65l2.1,2.1L37.65,14.3a18.437,18.437,0,0,1,3.075,4.85A16.561,16.561,0,0,1,42,25.95a17.292,17.292,0,0,1-1.425,6.975,18.267,18.267,0,0,1-9.6,9.6A17.292,17.292,0,0,1,24,43.95Zm0-3a14.465,14.465,0,0,0,10.625-4.375A14.465,14.465,0,0,0,39,25.95a14.465,14.465,0,0,0-4.375-10.625A14.465,14.465,0,0,0,24,10.95a14.465,14.465,0,0,0-10.625,4.375A14.465,14.465,0,0,0,9,25.95a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,40.95ZM24,26Z"
                              transform="translate(-6 -2)"
                            ></path>
                          </svg>
                          {(
                            (new Date(new Date(endDate)) -
                              new Date(
                                new Date(
                                  product.itemCreationDate
                                ).toDateString()
                              )) /
                            (1000 * 60 * 60 * 24)
                          ).toFixed() == 0
                            ? "Today"
                            : `${(
                                (new Date(new Date(endDate)) -
                                  new Date(
                                    new Date(
                                      product.itemCreationDate
                                    ).toDateString()
                                  )) /
                                (1000 * 60 * 60 * 24)
                              ).toFixed()}`}
                        </div>
                        <Dropdown drop="end" className="folder-details">
                          <DropdownToggle
                            variant=""
                            className="folder-btn no-caret"
                          >
                            <span className="foldericon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="31.95"
                                viewBox="0 0 576 512"
                              >
                                <path
                                  id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                  d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                  transform="translate(-2 -8)"
                                />
                              </svg>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            {folderNames.length > 0 ? (
                              folderNames.map((fdetail) => {
                                return (
                                  <DropdownItem
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      saveProduct(fdetail._id, product);
                                      e.preventDefault();
                                    }}
                                  >
                                    {fdetail.folderName}
                                  </DropdownItem>
                                );
                              })
                            ) : (
                              <DropdownItem className="dropdown-item">
                                {" "}
                                {show ? "Loading ..." : "Folders not found!"}
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                        {/* <div className="folder-details">
                          <button
                            type="button"
                            className="folder-btn"
                            onClick={() => {
                              getAllFolders();
                              setShow(true);
                            }}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            title="Save Product"
                          >
                            <span className="foldericon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="31.95"
                                viewBox="0 0 576 512"
                              >
                                <path
                                  id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                  d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                  transform="translate(-2 -8)"
                                />
                              </svg>
                            </span>
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            {folderNames.length > 0 ? (
                              folderNames.map((fdetail) => {
                                return (
                                  <li>
                                    <span
                                      className="dropdown-item"
                                      onClick={() => {
                                        saveProduct(fdetail._id, product);
                                      }}
                                    >
                                      {fdetail.folderName}
                                    </span>
                                  </li>
                                );
                              })
                            ) : (
                              <li>
                                <span className="dropdown-item">
                                  {" "}
                                  {show ? "Loading ..." : "Folders not found!"}
                                </span>
                              </li>
                            )}
                          </ul>
                        </div> */}
                      </div>
                    )}
                  </Carousel>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="product-detailsbody">
                    <div className="product-detailsinfo" id="title_product">
                      <span className="">{product.title}</span>
                    </div>
                    <span className="product-heading mt-2 product-title">
                      {product.shortDescription}
                    </span>
                    <div className="product-costinfo">
                      <span className="product-cost me-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40.1"
                          height="40.05"
                          viewBox="0 0 40.1 40.05"
                        >
                          <path
                            id="sell_FILL0_wght400_GRAD0_opsz48"
                            d="M20.15,43.15a3.078,3.078,0,0,0,4.35,0L43.25,24.4a2.624,2.624,0,0,0,.675-1.05A3.77,3.77,0,0,0,44.1,22.2V7a2.907,2.907,0,0,0-3-3H25.9a4.267,4.267,0,0,0-1.2.175,2.568,2.568,0,0,0-1.1.675L4.95,23.5a3.081,3.081,0,0,0,0,4.45ZM22.2,41.1,7,25.9,25.9,7H41.1V22.2ZM35.85,14.8a2.581,2.581,0,0,1-1.825-4.425,2.581,2.581,0,1,1,3.65,3.65,2.5,2.5,0,0,1-1.825.775ZM41.1,7Z"
                            transform="translate(-4 -4)"
                          ></path>
                        </svg>
                        ${product.price.value}
                      </span>
                      <div className="totalcost">
                        <p className="mb-0">Total Cost</p>

                        <span>${product.price.value}</span>
                      </div>
                    </div>
                    <div className="product-delinfo">
                      {product.shippingOptions !== undefined &&
                        product.shippingOptions.map((e) => {
                          return (
                            <span className="shipping-price">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="31.95"
                                viewBox="0 0 44 31.95"
                              >
                                <path
                                  id="local_shipping_FILL0_wght400_GRAD0_opsz48"
                                  d="M11.2,39.95a5.687,5.687,0,0,1-4.175-1.725A5.687,5.687,0,0,1,5.3,34.05H2V11a2.878,2.878,0,0,1,.9-2.1A2.878,2.878,0,0,1,5,8H33.95v8.35H39.2L46,25.4v8.65H42.45a5.9,5.9,0,0,1-10.075,4.175A5.687,5.687,0,0,1,30.65,34.05H17.1a5.687,5.687,0,0,1-1.725,4.175A5.687,5.687,0,0,1,11.2,39.95Zm0-3A2.9,2.9,0,1,0,9.15,32a2.9,2.9,0,0,0,2.05,4.95ZM5,31.05H6.1A6.625,6.625,0,0,1,8.25,28.9a5.423,5.423,0,0,1,2.9-.8,5.425,5.425,0,0,1,2.925.825A6.967,6.967,0,0,1,16.25,31.05h14.7V11H5Zm31.55,5.9a2.9,2.9,0,1,0-2.05-.85A2.794,2.794,0,0,0,36.55,36.95Zm-2.6-10.2h9.3l-5.55-7.4H33.95ZM18,21.55Z"
                                  transform="translate(-2 -8)"
                                ></path>
                              </svg>

                              {e.shippingCost !== undefined
                                ? e.shippingCost.value == 0
                                  ? `${e.type} : free`
                                  : `${e.type} : $${e.shippingCost.value}`
                                : "free"}
                              <br />
                            </span>
                          );
                        })}
                    </div>
                    <div className="product-info">
                      <span className="popularity">
                        <strong>Product Details</strong>
                      </span>
                    </div>

                    <div className="product-info">
                      <span className="popularity">
                        <strong>Item specifics</strong>
                      </span>
                    </div>
                    <span className="product-heading mt-3 mb-3">
                      <strong>Condition</strong>&nbsp;: {product.condition}
                      <br />
                    </span>
                    {product.localizedAspects.map((itemSpecifics) => {
                      return (
                        <span className="product-heading mt-3 mb-3">
                          <strong>{itemSpecifics.name}</strong>&nbsp; : &nbsp;
                          {itemSpecifics.value}
                          <br />
                        </span>
                      );
                    })}
                    <a href={`${product.itemWebUrl}`} target="blank">
                      <div className="mt-5">
                        <button className="btnshare m-0">
                          <span>Go to Listing!</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="29.531"
                            height="26.25"
                            viewBox="0 0 29.531 26.25"
                          >
                            <path
                              id="Icon_awesome-share-square"
                              data-name="Icon awesome-share-square"
                              d="M29.146,9.1,21.763,16.07a1.231,1.231,0,0,1-2.075-.895v-3.69c-7.412.05-10.539,1.8-8.448,8.785a.821.821,0,0,1-1.282.889,9.869,9.869,0,0,1-3.805-7.35c0-7.38,6.029-8.844,13.535-8.886V1.231A1.231,1.231,0,0,1,21.763.337l7.383,6.972A1.231,1.231,0,0,1,29.146,9.1Zm-9.458,10.34v3.531H3.281V6.562h2.61a.615.615,0,0,0,.443-.189A9.995,9.995,0,0,1,8.951,4.442a.615.615,0,0,0-.284-1.16H2.461A2.461,2.461,0,0,0,0,5.742V23.789A2.461,2.461,0,0,0,2.461,26.25H20.508a2.461,2.461,0,0,0,2.461-2.461V19.236a.615.615,0,0,0-.821-.579,3.683,3.683,0,0,1-1.753.173A.616.616,0,0,0,19.688,19.438Z"
                              transform="translate(0 0)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 dynamic-data mt-5 cstmscroll">
                  <iframe
                    id="frame"
                    onLoad={() => {
                      setHeight();
                    }}
                    style={{ width: "100%", height: "100vh" }}
                    srcdoc={product.description}
                  ></iframe>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </main>
          <ToastContainer autoClose={8000} position="top-center" />
        </div>
      </div>
    </>
  );
};
export default ProductDetails;
