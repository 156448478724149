import React, { useState } from "react";
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import PrivacyPolicy from "../pages/privacyPolicy";
import TermsCondition from "./termsCondition";
import { pageId, searchKeyword, searchBarcode } from "../redux/actions/users";
import axios from "axios";

const Mainheader = () => {
  const [show, setShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const Product_details = useSelector(
    (state) => state.getproductbykeywordReducer
  );
  const cancelStatus = useSelector((state) => state.getcancelSubscription);
  const searchdatakeyword = useSelector((state) => state.getsearchKeyword);
  const searchdatabarcode = useSelector((state) => state.getsearchBarcode);
  const cancelled = cancelStatus.response;
  const subType = useSelector((state) => state.getsubscriptionType);
  const navigate = useNavigate();
  let { Product_name, bar_code } = useParams();
  const mostRecentSearch = localStorage.getItem("mostRecentSearch");

  const handlelogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const showtermsModal = () => {
    setIsTermsOpen(true);
  };
  const hidetermsModal = () => {
    setIsTermsOpen(false);
  };

  let current_month = new Date();
  let lastday = new Date(
    current_month.getFullYear(),
    current_month.getMonth() + 1,
    0
  );

  let nextMonthday = new Date(
    current_month.getFullYear(),
    current_month.getMonth() + 1,
    1
  );

  const searchCheck = () => {
    let linkTo = `/searchlist/${null}`;
    if (
      searchdatakeyword.response === "" &&
      searchdatabarcode.response === ""
    ) {
      if (mostRecentSearch !== null) {
        let recentSearchJson = JSON.parse(mostRecentSearch);
        recentSearchJson["searchType"] == "keyword"
          ? (linkTo = `/searchlist/${recentSearchJson["searchTerm"]}`)
          : (linkTo = `/search-list-by-barcode/${mostRecentSearch["searchTerm"]}`);
      }
    }

    searchdatakeyword.response !== "" &&
      (linkTo = `/searchlist/${searchdatakeyword.response}`);
    searchdatabarcode.response !== "" &&
      (linkTo = `/search-list-by-barcode/${searchdatabarcode.response}`);

    return linkTo;
  };

  const createPortalSession = async () => {
    const user = localStorage.getItem("userID");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URI}/createPortal`,
        {
          userID: user,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const portalURL = res.data;
        // console.log(portalURL);
        window.location.href = portalURL;
      });
  };

  return (
    <>
      <header className="headerSec">
        <nav className="navbar navbar-light home_navigation">
          <div className="container">
            <Link className="navbar-brand" to="/home">
              <img
                src={`${process.env.PUBLIC_URL}/Images/Logo.png`}
                alt="logo"
              />
            </Link>
            <div className="custom-nav">
              <div className="d-flex ">
                <div className="nav-wrapper-header">
                  <NavLink
                    to="/home"
                    className="nav_icon_link"
                    // onClick={() => {
                    //   dispatch(searchKeyword(""));
                    //   dispatch(searchBarcode(""));
                    // }}
                  >
                    <span className="nav_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                      >
                        <g
                          id="Group_23357"
                          data-name="Group 23357"
                          transform="translate(-1258 -292)"
                        >
                          <g
                            id="Group_23355"
                            data-name="Group 23355"
                            transform="translate(1258 292)"
                          >
                            <rect
                              id="Rectangle_17818"
                              data-name="Rectangle 17818"
                              width="27"
                              height="27"
                              opacity="0"
                            />
                            <g
                              id="Group_23354"
                              data-name="Group 23354"
                              transform="translate(1.534 1.719)"
                            >
                              <path
                                id="Path_16241"
                                data-name="Path 16241"
                                d="M2.868,22.965l-.1-7.809A32.954,32.954,0,0,1,13.678,3.69a1.876,1.876,0,0,1,2.116,0A32.953,32.953,0,0,1,26.7,15.156l-.1,7.809a4,4,0,0,1-3.509,3.5,75.743,75.743,0,0,1-16.718,0A4,4,0,0,1,2.868,22.965Z"
                                transform="translate(-2.77 -3.363)"
                                fill="none"
                                stroke="#4ea459"
                                stroke-linejoin="round"
                                stroke-width="2.5"
                              />
                            </g>
                          </g>
                          <g
                            id="Group_23356"
                            data-name="Group 23356"
                            transform="translate(1265.8 302.2)"
                          >
                            <circle
                              id="Ellipse_239"
                              data-name="Ellipse 239"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              fill="#4ea459"
                            />
                            <circle
                              id="Ellipse_241"
                              data-name="Ellipse 241"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              transform="translate(0 7)"
                              fill="#4ea459"
                            />
                            <circle
                              id="Ellipse_240"
                              data-name="Ellipse 240"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              transform="translate(7)"
                              fill="#4ea459"
                            />
                            <circle
                              id="Ellipse_242"
                              data-name="Ellipse 242"
                              cx="2.5"
                              cy="2.5"
                              r="2.5"
                              transform="translate(7 7)"
                              fill="#4ea459"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Research
                  </NavLink>
                  <NavLink to="/comp-history" className="nav_icon_link">
                    <span className="nav_icon">
                      <svg
                        id="Group_23393"
                        data-name="Group 23393"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27.165"
                        height="27"
                        viewBox="0 0 27.165 27"
                      >
                        <rect
                          id="Rectangle_17824"
                          data-name="Rectangle 17824"
                          width="27"
                          height="27"
                          opacity="0"
                        />
                        <g
                          id="Group_23392"
                          data-name="Group 23392"
                          transform="translate(2.253 1.682)"
                        >
                          <g
                            id="Group_23391"
                            data-name="Group 23391"
                            transform="translate(0 0)"
                          >
                            <g id="Group_23386" data-name="Group 23386">
                              <path
                                id="Path_16251"
                                data-name="Path 16251"
                                d="M11.837,1.555a1.2,1.2,0,0,1,2.126,0L17.651,8.73H23.7a1.154,1.154,0,0,1,.838,1.98l-5.1,4.972,2.626,7.674a1.177,1.177,0,0,1-1.683,1.388L12.9,20.889,5.423,24.745A1.177,1.177,0,0,1,3.74,23.356l2.626-7.674-5.1-4.972a1.154,1.154,0,0,1,.84-1.98H8.15Z"
                                transform="translate(-0.917 -0.915)"
                                fill="none"
                                stroke="#8d8d8e"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.83"
                              />
                            </g>
                            <path
                              id="Path_16252"
                              data-name="Path 16252"
                              d="M1.143,0A1.479,1.479,0,0,1,2.438,1.5,1.479,1.479,0,0,1,1.143,3,1.521,1.521,0,0,1-.2,1.5,1.521,1.521,0,0,1,1.143,0Z"
                              transform="translate(17.747 13.318)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    Review
                  </NavLink>
                  <NavLink
                    to={
                      searchCheck()
                      //   searchdatakeyword.response === ""
                      //     ? searchdatabarcode.response === ""
                      //       ? mostRecentSearch["searchType"] == "keyword"
                      //         ? `/searchlist/${
                      //             mostRecentSearch["serachTerm"] !== ""
                      //               ? mostRecentSearch["searchTerm"]
                      //               : null
                      //           }`
                      //         : `/search-list-by-barcode/${mostRecentSearch["searchTerm"]}`
                      //       : `/search-list-by-barcode/${searchdatabarcode.response}`
                      //     : `/searchlist/${searchdatakeyword.response}`
                    }
                    onClick={() => {
                      dispatch(pageId(1));
                    }}
                    className="nav_icon_link"
                  >
                    <span className="nav_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.83"
                        height="28.83"
                        viewBox="0 0 28.83 28.83"
                      >
                        <g
                          id="Group_23370"
                          data-name="Group 23370"
                          transform="translate(-4.785 -10.585)"
                        >
                          <g
                            id="Group_23369"
                            data-name="Group 23369"
                            transform="translate(5.7 11.5)"
                          >
                            <g
                              id="Group_23368"
                              data-name="Group 23368"
                              transform="translate(0 0)"
                            >
                              <g id="Group_23359" data-name="Group 23359">
                                <rect
                                  id="Rectangle_17821"
                                  data-name="Rectangle 17821"
                                  width="27"
                                  height="27"
                                  rx="5"
                                  fill="none"
                                  stroke="#8d8d8e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.83"
                                />
                              </g>
                              <g
                                id="Group_23374"
                                data-name="Group 23374"
                                transform="translate(8.356 12.187)"
                              >
                                <circle
                                  id="Ellipse_243"
                                  data-name="Ellipse 243"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(0 0)"
                                  fill="#8d8d8e"
                                />
                                <circle
                                  id="Ellipse_244"
                                  data-name="Ellipse 244"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(5 0)"
                                  fill="#8d8d8e"
                                />
                                <circle
                                  id="Ellipse_245"
                                  data-name="Ellipse 245"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(10 0)"
                                  fill="#8d8d8e"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    Results
                  </NavLink>
                  <NavLink to="/folders-page" className="nav_icon_link">
                    <span className="nav_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28.83"
                        height="28.83"
                        viewBox="0 0 28.83 28.83"
                      >
                        <g
                          id="Group_23370"
                          data-name="Group 23370"
                          transform="translate(-4.535 -10.585)"
                        >
                          <g
                            id="Group_23369"
                            data-name="Group 23369"
                            transform="translate(5.45 11.5)"
                          >
                            <g
                              id="Group_23368"
                              data-name="Group 23368"
                              transform="translate(0 0)"
                            >
                              <g id="Group_23359" data-name="Group 23359">
                                <rect
                                  id="Rectangle_17821"
                                  data-name="Rectangle 17821"
                                  width="27"
                                  height="27"
                                  rx="7"
                                  fill="none"
                                  stroke="#8d8d8e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.83"
                                />
                              </g>
                              <g
                                id="Group_23364"
                                data-name="Group 23364"
                                transform="translate(4.665 7.4)"
                              >
                                <line
                                  id="Line_327"
                                  data-name="Line 327"
                                  x2="17.16"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#8d8d8e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.83"
                                />
                              </g>
                              <g
                                id="Group_23366"
                                data-name="Group 23366"
                                transform="translate(4.665 13.632)"
                              >
                                <line
                                  id="Line_329"
                                  data-name="Line 329"
                                  x2="17.16"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#8d8d8e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.83"
                                />
                              </g>
                              <g
                                id="Group_23371"
                                data-name="Group 23371"
                                transform="translate(4.665 19.864)"
                              >
                                <line
                                  id="Line_329-2"
                                  data-name="Line 329"
                                  x2="17.16"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#8d8d8e"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.83"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    Folders
                  </NavLink>
                </div>
              </div>
              <div className="dropdown_wrapper">
                <button
                  onClick={() => setShow(!show)}
                  className="btn custom_toggle_btn"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.4"
                    height="36.6"
                    viewBox="0 0 36.4 36.6"
                  >
                    <path
                      id="tune_FILL0_wght500_GRAD0_opsz48"
                      d="M21.3,42.3V30.75h3.25V34.9H42.2v3.3H24.55v4.1ZM5.8,38.2V34.9H18.4v3.3Zm9.35-8.45v-4.1H5.8v-3.3h9.35v-4.2H18.4v11.6Zm6.15-4.1v-3.3H42.2v3.3Zm8.3-8.4V5.7h3.25V9.8H42.2v3.3H32.85v4.15ZM5.8,13.1V9.8H26.7v3.3Z"
                      transform="translate(-5.8 -5.7)"
                    />
                  </svg>
                </button>
                <div className={show ? "custom_dropdown" : "custom_dropdown1"}>
                  <button
                    className="close_btn btn"
                    onClick={() => setShow(!show)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                    >
                      <path
                        id="Icon_material-close"
                        data-name="Icon material-close"
                        d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
                        transform="translate(-7.5 -7.5)"
                      />
                    </svg>
                  </button>
                  <div className="custom_dropdown_nav ms-5">
                    <Link to="/profile-setting">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="31.05"
                          viewBox="0 0 32 31.05"
                        >
                          <path
                            id="person_FILL0_wght400_GRAD0_opsz48"
                            d="M24,23.95a7.193,7.193,0,0,1-7.5-7.5A7.193,7.193,0,0,1,24,8.95a7.193,7.193,0,0,1,7.5,7.5,7.193,7.193,0,0,1-7.5,7.5ZM8,40V35.3a5.5,5.5,0,0,1,.95-3.25A6.1,6.1,0,0,1,11.4,30a37.583,37.583,0,0,1,6.425-2.25A25.973,25.973,0,0,1,24,27a24.88,24.88,0,0,1,6.15.775A42.593,42.593,0,0,1,36.55,30a6.028,6.028,0,0,1,2.5,2.05A5.5,5.5,0,0,1,40,35.3V40Zm3-3H37V35.3a2.739,2.739,0,0,0-.475-1.525A3.01,3.01,0,0,0,35.35,32.7a26.551,26.551,0,0,0-5.85-2.125A25.869,25.869,0,0,0,24,30a26.573,26.573,0,0,0-5.55.575A25.4,25.4,0,0,0,12.6,32.7,3.008,3.008,0,0,0,11,35.3ZM24,20.95a4.361,4.361,0,0,0,4.5-4.5,4.361,4.361,0,0,0-4.5-4.5,4.361,4.361,0,0,0-4.5,4.5,4.361,4.361,0,0,0,4.5,4.5ZM24,16.45ZM24,37Z"
                            transform="translate(-8 -8.95)"
                          />
                        </svg>
                      </span>
                      Profile Settings
                    </Link>
                    <a href="https://myresellerapp.com" target="_blank">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                        >
                          <path
                            id="info_FILL0_wght400_GRAD0_opsz48"
                            d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                            transform="translate(-4 -4)"
                          />
                        </svg>
                      </span>
                      About ReSeller
                    </a>
                    <Link
                      onClick={() => {
                        showModal();
                        setShow(!show);
                      }}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="40"
                          viewBox="0 0 32 40"
                        >
                          <path
                            id="verified_user_FILL0_wght400_GRAD0_opsz48"
                            d="M21.8,30.65l11.4-11.4L31.1,17.2l-9.15,9.15L16.9,21.3l-2.2,2.2ZM24,43.95a19.635,19.635,0,0,1-11.5-8.125A23.715,23.715,0,0,1,8,21.85V9.95l16-6,16,6v11.9a23.715,23.715,0,0,1-4.5,13.975A19.635,19.635,0,0,1,24,43.95Zm0-3.1a18.038,18.038,0,0,0,9.375-7.175A20.4,20.4,0,0,0,37,21.85v-9.8L24,7.15l-13,4.9v9.8a20.4,20.4,0,0,0,3.625,11.825A18.038,18.038,0,0,0,24,40.85ZM24,24Z"
                            transform="translate(-8 -3.95)"
                          />
                        </svg>
                      </span>
                      Privacy Policy
                    </Link>
                    <Link onClick={createPortalSession}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm175 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                      </svg>
                      Subscription
                    </Link>
                    {/* {cancelled == false ? 
                                            <Link to="/subscription">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm175 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
                                                Cancel Subscription
                                            </Link> : ''
                                        } */}
                    {/* {subType.response == "trial" &&
                    current_month.getTime() < nextMonthday.getTime() &&
                    cancelled == false ? (
                      <Link to="/trial">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm175 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                        </svg>
                        Cancel Trial
                      </Link>
                    ) : cancelled == false ? (
                      <Link to="/subscription">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm175 79c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                        </svg>
                        Cancel Subscription
                      </Link>
                    ) : (
                      ""
                    )} */}
                    <Link
                      onClick={() => {
                        showtermsModal();
                        setShow(!show);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="40"
                        viewBox="0 0 36 40"
                      >
                        <path
                          id="assignment_turned_in_FILL0_wght400_GRAD0_opsz48"
                          d="M21.15,31.55,35,17.7l-2.15-2.15-11.7,11.7L15.1,21.2,13,23.3ZM9,42a2.988,2.988,0,0,1-3-3V9A2.988,2.988,0,0,1,9,6H19.25a4.437,4.437,0,0,1,1.6-2.875,4.972,4.972,0,0,1,6.3,0A4.437,4.437,0,0,1,28.75,6H39a2.988,2.988,0,0,1,3,3V39a2.988,2.988,0,0,1-3,3Zm0-3H39V9H9ZM24,8.15a1.679,1.679,0,0,0,1.225-.525,1.692,1.692,0,0,0,0-2.45,1.692,1.692,0,0,0-2.45,0,1.692,1.692,0,0,0,0,2.45A1.679,1.679,0,0,0,24,8.15ZM9,39v0Z"
                          transform="translate(-6 -2)"
                        />
                      </svg>
                      Terms of Use
                    </Link>
                    <Link to="/change-password">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
                      </svg>
                      Change Password
                    </Link>
                    <button
                      className="my-btn"
                      onClick={(e) => {
                        handlelogout(e);
                      }}
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 36 36"
                        >
                          <path
                            id="logout_FILL0_wght400_GRAD0_opsz48"
                            d="M9,42a2.878,2.878,0,0,1-2.1-.9A2.878,2.878,0,0,1,6,39V9a2.878,2.878,0,0,1,.9-2.1A2.878,2.878,0,0,1,9,6H23.55V9H9V39H23.55v3Zm24.3-9.25L31.15,30.6l5.1-5.1H18.75v-3h17.4l-5.1-5.1,2.15-2.15,8.8,8.8Z"
                            transform="translate(-6 -6)"
                          />
                        </svg>
                      </span>
                      Log Out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div
        className={show ? "custom_page_dropshadow" : "custom_page_dropshadow1"}
      ></div>
      <Modal className="modal fade modal-xl" show={isOpen} id="privacymodal">
        <Modal.Header>
          <h4>Reseller Privacy Policy</h4>
          <button
            type="button"
            className="close custom-close"
            onClick={hideModal}
            data-dismiss="modal"
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={hideModal}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* terms and condition modal */}
      <Modal
        className="modal fade modal-xl"
        id="termscondition"
        show={isTermsOpen}
      >
        <Modal.Header>
          <h4>ReSeller Terms of Use</h4>
          <button
            type="button"
            className="close custom-close"
            onClick={hidetermsModal}
            data-dismiss="modal"
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <TermsCondition />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={hidetermsModal}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default Mainheader;
