import React, { useState } from "react";
import AdminHeader from "../components/adminHeader";
import AdminSidebar from "../components/adminSidebar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const VerocsvFile = () => {
  const [csvfile, setCsvfile] = useState(undefined);

  let CsvfileChange = (e) => {
    let files = e.target.files[0];
    setCsvfile(files);
  };

  let formData = new FormData();
  formData.append("file", csvfile);

  //upload vero csv file.
  let Uploadfile = () => {
    if (csvfile !== undefined) {
      if (csvfile.name === "vero.csv") {
        axios
          .post(
            `${process.env.REACT_APP_SERVER_BASE_URI}/uploadcsv`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toast.success("VERO Heroes file uploaded successfully");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((error) => {
            throw error;
          });
      } else {
        toast.error("File name should be vero.csv");
      }
    } else {
      toast.error("Please choose a file to upload");
    }
  };

  return (
    <>
      <div className="dashboard-section">
        <div className="bg-pattern"></div>
        {/* <!----- Sidebar Section Start -----> */}
        <AdminSidebar />
        {/* <!----- Dashboard Body Start -----> */}
        {/* {isloading ? */}
        <div className="dashboard-body">
          <AdminHeader />
          <div className="page-content">
            <div className="page-head-sec csv">
              <h3>Upload CSV File</h3>
            </div>
            <div className="mb-3 customInput_Box mt-5">
              <form encType="multipart/form-data">
                <span className="asterisk_input"> </span>
                <label className="filelabel csvlabel">
                  <i className="fa-solid fa-image uploadImage"></i>

                  <input
                    className="form-control FileUpload1"
                    id="file"
                    name="file"
                    onChange={CsvfileChange}
                    type="file"
                    accept=".csv"
                  />
                </label>
                <button
                  type="button"
                  onClick={Uploadfile}
                  className="btn btn-primary mt-4"
                >
                  Upload file
                </button>
              </form>
            </div>
          </div>
          <ToastContainer autoClose={8000} position="top-center" />
        </div>
      </div>
    </>
  );
};

export default VerocsvFile;
