import { combineReducers } from "redux";
import {
  signinReducer,
  profileReducer,
  getproductbykeywordReducer,
  getMaxPageNumber,
  getconditionMessage,
  getsubscriptionType,
  getMinPageNumber,
  getsearchKeyword,
  getfilterMessage,
  getsearchBarcode,
  getsoldproductbykeywordReducer,
  sortReducer,
  rangeReducer,
  singleProductDataReducer,
  getsubscriptionId,
  getpageId,
  getroleId,
  getsortMessage,
  getcancelSubscription,
  getpaidData,
  getperPageData,
  getbackButton,
  getupdatedData,
  getfilterReducer,
  getconditionReducer,
} from "./usersReducer";
import { listingReducer, componetReRenderReducer } from "./listingReducer";
const rootReducer = combineReducers({
  signinReducer,
  profileReducer,
  getproductbykeywordReducer,
  getsoldproductbykeywordReducer,
  sortReducer,
  rangeReducer,
  singleProductDataReducer,
  listingReducer,
  componetReRenderReducer,
  getsubscriptionId,
  getpageId,
  getroleId,
  getsortMessage,
  getcancelSubscription,
  getsearchKeyword,
  getsearchBarcode,
  getfilterMessage,
  getpaidData,
  getperPageData,
  getbackButton,
  getupdatedData,
  getMaxPageNumber,
  getMinPageNumber,
  getsubscriptionType,
  getconditionMessage,
  getfilterReducer,
  getconditionReducer,
});

export default rootReducer;
