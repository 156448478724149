import React, { useState } from "react";
import Header from "../components/header";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/loader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isloading, setIsloading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      setIsloading(true);
      const data = {
        email: values.email,
      };
      //forgot password API.
      axios
        .post(`${process.env.REACT_APP_SERVER_BASE_URI}/forGotPassword`, data)
        .then((response) => {
          if (response.data.status == 200) {
            setBtnDisabled(true);
            setTimeout(() => {
              toast.success("OTP sent successfully");
            }, 1000);
            navigate("/otp-verification");
            localStorage.setItem(
              "email",
              JSON.parse(response.config.data).email
            );
          }

          if (response.data.status == 400) {
            setIsloading(false);
            toast.error("Email does not exist");
          }
        })
        .catch((err) => {});
    },

    //Here we validate our email field with yup and formik.
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address!")
        .required("Please enter your email address!"),
    }),
  });
  return (
    <div className="bg-img">
      <Header />
      {!isloading ? (
        <section className="signUp_sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-2 col-md-2">
                <div className="signUp_Box"></div>
              </div>
              <div className="col-xl-6 col-lg-8 col-md-8">
                <div className="signUp_Box fspan px-lg-5">
                  <h1>Know before you buy</h1>
                  <p>Forgot Password?</p>
                  <h6 className="instruct">
                    No worries, we will send you reset instructions
                  </h6>
                  <form
                    className="signUpForm_box"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="customInput_Box">
                      <span className="asterisk_input"> </span>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>

                    <div className="signUpForm_BTN reBtn mt-3">
                      <button
                        className="btn"
                        type="submit"
                        disabled={btnDisabled}
                      >
                        Send One Time Password
                      </button>

                      <span>
                        <Link to="/">&#8592; Back to log in</Link>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <Loader />
      )}
      <ToastContainer autoClose={8000} position="top-center" />
    </div>
  );
};
export default ForgotPassword;
