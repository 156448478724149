import React from 'react'
import { NavLink} from "react-router-dom";
const AdminSidebar = () => {
    return (
        <>
            <aside className="sidebar-sec">
                <div className="logo-box">
                    <img src={`${process.env.PUBLIC_URL}/Images/Logo.png`} alt="logo" className=" img-fluid" />
                </div>
                <div className="sidebar-menu">
                    <ul className="dashboard-list">
                        <li>
                            <NavLink to="/admin/dashboard">
                                <div className="d-flex align-items-center">
                                    <i className="bx bxs-dashboard"></i>
                                    <span className="link-name">Dashboard</span>
                                </div>

                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/create-admin">
                                <div className="d-flex align-items-center">
                                    <i className="bx bxs-user-plus"></i>
                                    <span className="link-name">Add Admin</span>
                                </div>
                            </NavLink>
                        </li>
                        <li>
                                <NavLink to="/admin/vero-csv">
                                    <div className="d-flex align-items-center">

                                        <i className='bx bxs-file-plus'></i>
                                        <span className="link-name">Add CSV File</span>
                                    </div>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/admin/delete-users">
                                    <div className="d-flex align-items-center">
                                        <i className='bx bxs-user-x'></i>
                                        <span className="link-name">Restore Customers</span>
                                    </div>
                                </NavLink>
                            </li>
                    </ul>
                </div>
            </aside>
        </>
    )
}

export default AdminSidebar